import React from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  FaUser,
  FaCalendarAlt,
  FaEnvelope,
  FaPhone,
  FaBuilding,
  FaMoneyBill,
  FaLock,
} from "react-icons/fa";
import { app_url } from "../../config";
import activeCheck from "../../../src/assets/images/check.png";

const PartnersCard = ({ tableData, isLoading }) => {
  const navigate = useNavigate();

  const handleCardClick = (id) => {
    navigate(`/partners/${id}`);
  };

  return (
    <div className="mt-4">
      <div className="row">
        {isLoading ? (
          Array.from({ length: 12 }).map((_, index) => (
            <div key={index} className="col-md-4 mb-4">
              <div className="card skeleton-card">
                <div className="card-body">
                  <div className="placeholder-glow">
                    <span className="placeholder col-12"></span>
                    <span className="placeholder col-12"></span>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : tableData?.data?.result?.length === 0 ? (
          <p>No Data Found</p>
        ) : (
          tableData?.data?.result?.map(
            ({
              id,
              image,
              username,
              agent_name,
              email,
              mobile,
              company_name,
              company_phone,
              agent_status,
              agent_currency,
              account_mode,
              created_at,
            }) => (
              <div key={id} className="col-xl-4 col-md-6 mb-4">
                <div
                  className="card partner-card h-100 partner-card"
                  onClick={() => handleCardClick(id)}
                  style={{ cursor: "pointer" }}
                >
                  <div className="card-body">
                    <div className="d-flex flex-column justify-center align-items-center">
                      <div
                        className="position-relative"
                        style={{
                          width: "120px",
                          height: "120px",
                          borderRadius: "100%",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <img
                          src={app_url + image}
                          alt="profile image"
                          loading="lazy"
                          width={120}
                          height={120}
                          style={{ borderRadius: "100%" }}
                        />
                        {agent_status === 1 ? (
                          <div
                            className="position-absolute"
                            style={{
                              position: "absolute",
                              bottom: "15px",
                              right: "5px",
                            }}
                          >
                            <img
                              src={activeCheck}
                              alt="check icon"
                              width={20}
                              height={20}
                            />
                          </div>
                        ) : null}
                      </div>
                      <h4
                        className="card-title mt-1 text-center"
                        style={{ color: "#F57C21" }}
                      >
                        {agent_name}
                      </h4>
                    </div>

                    <div className="row text-center mt-3">
                      <div className="col">
                        <p className="mb-0">Username</p>
                        <h4>{username || "N/A"}</h4>
                      </div>
                      <div className="col">
                        <p className="mb-0">Agent Currency</p>
                        <h4>{agent_currency || "N/A"}</h4>
                      </div>
                      <div className="col">
                        <p className="mb-0">Account Mode</p>
                        <h4>{account_mode || "N/A"}</h4>
                      </div>
                    </div>

                    <div className="">
                      <h5 className=" mt-3" style={{ color: "#909090" }}>
                        Contact Information
                      </h5>
                      <div className="mt-2">
                        <p
                          className="d-flex align-items-center gap-3 mb-0"
                          style={{ wordBreak: "break-all" }}
                        >
                          <FaEnvelope /> <strong>Email:</strong>{" "}
                          {email || "N/A"}
                        </p>
                        <p className="d-flex align-items-center gap-3">
                          <FaPhone /> <strong>Mobile:</strong> {mobile || "N/A"}
                        </p>
                      </div>
                    </div>
                    <div className="">
                      <h5 className=" mt-3" style={{ color: "#909090" }}>
                        Company Details
                      </h5>
                      <div className="mt-2">
                        <p className="d-flex align-items-center gap-3 mb-0">
                          <FaPhone /> <strong>Company Phone:</strong>{" "}
                          {company_phone || "N/A"}
                        </p>
                        <p className="d-flex align-items-center gap-3">
                          <FaBuilding /> <strong>Company Name:</strong>{" "}
                          {company_name || "N/A"}
                        </p>
                      </div>
                    </div>

                    <div className="">
                      <h5 className=" mt-3" style={{ color: "#909090" }}>
                        Account Information
                      </h5>
                      <div className="mt-2">
                        {/* <p className="d-flex align-items-center gap-3 mb-0">
                          <FaLock /> <strong>Account Mode:</strong>{" "}
                          {account_mode || "N/A"}
                        </p> */}
                        <p className="d-flex align-items-center gap-3">
                          <FaCalendarAlt /> <strong>Joined:</strong>{" "}
                          {created_at
                            ? new Date(created_at).toLocaleString()
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          )
        )}
      </div>
    </div>
  );
};

export default PartnersCard;
