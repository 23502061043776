import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { app_url } from "../../config";
import Loader from "../Loader";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const getLastFiveYears = () => {
  const currentYear = new Date().getFullYear();
  return Array.from({ length: 5 }, (_, index) => currentYear - index);
};

const getDaysInMonth = (year, month) => {
  return new Date(year, month + 1, 0).getDate();
};

// console.log(getDaysInMonth, "get days in month check");

const ChartOne = ({ endPoint, totalName }) => {
  const navigate = useNavigate();
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [data, setData] = useState({ labels: [], datasets: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const token = JSON.parse(localStorage.getItem("adminEtijwaal"))?.token;

  console.log(selectedMonth + 1, "selected month check");

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          `${app_url}/api/dashboard/${endPoint}?month_year=${selectedYear}-${
            selectedMonth <= 8 ? "0" : ""
          }${selectedMonth + 1}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const daysInMonth = getDaysInMonth(selectedYear, selectedMonth);
        const labels = Array.from({ length: daysInMonth }, (_, i) => i + 1);

        // Extracting counts from the response
        const { count, total_count } = response.data;
        const { android_counts, ios_counts, website_counts, partner_counts } =
          count;

        // Ensure arrays are not null/undefined, default to empty array if they are
        const androidData =
          android_counts?.slice(0, daysInMonth) || Array(daysInMonth).fill(0);
        const iosData =
          ios_counts?.slice(0, daysInMonth) || Array(daysInMonth).fill(0);
        const websiteData =
          website_counts?.slice(0, daysInMonth) || Array(daysInMonth).fill(0);
        const partnerData =
          partner_counts?.slice(0, daysInMonth) || Array(daysInMonth).fill(0);
        const dataset = [
          {
            label: "Android",
            data: androidData,
            fill: false,
            backgroundColor: "#42A5F5",
            borderColor: "#1E88E5",
          },
          {
            label: "iOS",
            data: iosData,
            fill: false,
            backgroundColor: "#FF9130",
            borderColor: "#FF5B22",
          },
          {
            label: "Website",
            data: websiteData,
            fill: false,
            backgroundColor: "#737373",
            borderColor: "#403e3e",
          },
          {
            label: "Partners",
            data: partnerData,
            fill: false,
            backgroundColor: "#199E01",
            borderColor: "#158401",
          },
        ];

        setData({
          labels,
          datasets: dataset,
        });

        setTotalCount(total_count);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        if (err.response) {
          if (err.response.status === 400) {
            toast.error(err.response.data.message || "Bad Request");
          } else if (err.response.status === 401) {
            navigate("/");
          } else if (err.response.status === 500) {
            toast.error(err.response.data.message || "Internal Server Error");
          } else {
            toast.error("An unexpected error occurred.");
          }
        } else {
          setError("Network error. Please check your internet connection.");
        }
      }
    };
    fetchData();
  }, [selectedMonth, selectedYear, token, navigate]);

  const handleMonthChange = (e) => {
    setSelectedMonth(Number(e.target.value));
  };

  const handleYearChange = (e) => {
    setSelectedYear(Number(e.target.value));
  };

  const options = {
    responsive: true,
    interaction: {
      intersect: false,
      mode: "index",
    },
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Monthly Data",
      },
      tooltip: {
        callbacks: {
          title: (tooltipItems) => `Day: ${tooltipItems[0].label}`,
          label: (tooltipItem) =>
            `${tooltipItem.dataset.label}: ${tooltipItem.raw}`,
          footer: (tooltipItems) => {
            const sum = tooltipItems.reduce((acc, tooltipItem) => {
              return acc + tooltipItem.raw;
            }, 0);
            return `Total: ${sum}`;
          },
        },
      },
    },
  };

  // console.log(data, "data check");

  return (
    <div className="container mt-4">
      <div className="row mb-4">
        <div className="col">
          <label htmlFor="month-select" className="form-label fs-6">
            Select Month
          </label>
          <select
            id="month-select"
            className="form-select"
            value={selectedMonth}
            onChange={handleMonthChange}
          >
            {[...Array(12).keys()].map((month) => (
              <option key={month} value={month}>
                {new Date(0, month).toLocaleString("default", {
                  month: "long",
                })}
              </option>
            ))}
          </select>
        </div>
        <div className="col">
          <label htmlFor="year-select" className="form-label">
            Select Year
          </label>
          <select
            id="year-select"
            className="form-select"
            value={selectedYear}
            onChange={handleYearChange}
          >
            {getLastFiveYears().map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className=" d-flex align-items-end justify-content-end">
        <h6
          style={{
            margin: 0,
            fontWeight: "bold",
            color: "#333",
            border: "1px solid #ccc",
            padding: "8px 12px",
            borderRadius: "5px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          Total {totalName}: <span className="fw-normal"> {totalCount}</span>
        </h6>
      </div>
      {isLoading ? (
        <div
          style={{
            height: "20vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "8em",
          }}
        >
          <Loader />
        </div>
      ) : error ? (
        <div
          style={{
            height: "20vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "8em",
          }}
        >
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        </div>
      ) : (
        <Line data={data} options={options} width="100%" height="45%" />
      )}
    </div>
  );
};

export default ChartOne;
