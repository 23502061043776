import React, { useEffect, useState } from "react";
import Notifications from "../../components/Notifications/Notifications";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { app_url } from "../../config";
import toast from "react-hot-toast";
import Error500 from "../../components/500/Error500";

const AppNotifications = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  // const [data, setData] = useState([
  //   {
  //     title: "New Update Available",
  //     message:
  //       "A new update has been released for the application. Please update to the latest version.",
  //     created_at: "2023-08-30T10:20:30Z",
  //     status: "unread",
  //     thumbnail: "/images/notification1.png",
  //   },
  //   {
  //     title: "Maintenance Scheduled",
  //     message:
  //       "Our servers will be down for maintenance on September 1st, 2023.",
  //     created_at: "2023-08-25T15:10:15Z",
  //     status: "read",
  //     thumbnail: "/images/notification2.png",
  //   },
  //   {
  //     title: "New Feature Release",
  //     message:
  //       "We have released a new feature to enhance your experience. Check it out in the latest version!",
  //     created_at: "2023-08-20T08:45:00Z",
  //     status: "unread",
  //     thumbnail: "/images/notification3.png",
  //   },
  // ]);

  // const [online, setOnline] = useState(navigator.onLine);
  // const [failed, setFailed] = useState("");

  // const navigate = useNavigate();
  // const id = JSON.parse(localStorage.getItem("adminEtijwaal"))?.user?.id;
  // const token = JSON.parse(localStorage.getItem("adminEtijwaal"))?.token;

  // useEffect(() => {
  //   setIsLoading(true);
  //   axios
  //     .get(`${app_url}/api/notification/getNotificationListAdmin?id=${id}`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then((res) => {
  //       setIsLoading(false);
  //       setData(res.data.notif);
  //     })
  //     .catch((err) => {
  //       // if (err.response?.status === 401) {
  //       //   navigate("/");
  //       // }
  //       // else
  //       if (err.response?.status === 400) {
  //         toast.error(err.response?.data.message);
  //         setIsLoading(false);
  //       } else if (err.response?.status === 500) {
  //         setOnline(navigator.onLine);
  //         setIsLoading(false);
  //         setFailed(err.message);
  //       } else {
  //         setOnline(navigator.onLine);
  //         setFailed(err.message);
  //         setIsLoading(false);
  //       }
  //     });
  // }, []);

  // useEffect(() => {
  //   setOnline(navigator.onLine);
  // });

  // if (!online) {
  //   return <Error500 message={failed} />;
  // }

  return (
    <section className="wrapper">
      {
        <>
          <div className="d-flex flex-wrap gap-3 justify-content-between">
            <div className="d-flex align-items-center gap-2">
              <i
                className="bi bi-arrow-left-circle-fill fs-3 my-auto pointer"
                onClick={() => window.history.back()}
              ></i>
              <p className="heading" style={{ marginBottom: 0 }}>
                Notifications
              </p>
            </div>
          </div>
          <Notifications isLoading={isLoading} data={data} />
        </>
      }
    </section>
  );
};

export default AppNotifications;
