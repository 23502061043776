import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PartnersTable from "../../components/tables/PartnersTable";
import _ from "lodash";
import axios from "axios";
import { app_url } from "../../config";
import toast from "react-hot-toast";
import Error500 from "../../components/500/Error500";
import Pagination from "../../components/tables/Pagination";

const Partners = () => {
  const token = JSON.parse(localStorage.getItem("adminEtijwaal"))?.token;
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState("");
  const [dataOnPage, setDataOnPage] = useState(12);
  const [networkIssue, setNetworkIssue] = useState(navigator.onLine);
  const [failed, setFailed] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  // Pagination Calculation
  const itemsPerPage = dataOnPage;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Debounded Fetch function API Call
  const fetchPartners = useCallback(
    _.debounce((searchValue) => {
      setLoading(true);
      axios
        .get(
          `${app_url}/api/partner/getAllPartner?numPerPage=${dataOnPage}&page=${
            currentPage - 1
          }&search=${searchValue}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (itemsPerPage > res?.data?.pagination?.datCount) {
            setCurrentPage(1);
          }
          setTableData(res);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if (err.response) {
            const status = err.response.status;
            if (status === 400) {
              toast.error(err.response.data.message || "Bad Request");
            } else if (status === 401) {
              navigate("/");
            } else if (status === 500) {
              toast.error(err.response.data.message || "Internal Server Error");
            } else {
              toast.error("An unexpected error occurred.");
            }
          } else {
            setNetworkIssue(navigator.onLine);
            setFailed("Network error. Please check your internet connection.");
          }
        });
    }, 500),
    [dataOnPage, currentPage, token]
  );

  // useEffect to Fetch Data on Initial Render and Pagination Change
  useEffect(() => {
    fetchPartners(search);
  }, [search, dataOnPage, currentPage, token]);

  // Search Function with Debounce
  const searchData = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  // Handle Pagination Page Change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (!networkIssue) {
    return <Error500 message={failed} />;
  }

  return (
    <section className="wrapper">
      <div className="d-flex flex-wrap gap-3 justify-content-between">
        <div className="d-flex justify-content-between align-items-center w-100">
          <p className="heading mb-0"> Partners Center</p>
          <Link to={"/partners/addPartner"} className="btn card-btn py-2">
            Add Partner
          </Link>
        </div>
        <input
          style={{ width: 300 }}
          className="form-control"
          type="search"
          placeholder="Search Partner"
          aria-label="Search"
          value={search}
          onChange={searchData}
        ></input>
      </div>

      {/* PartnersTable */}
      <PartnersTable tableData={tableData} isLoading={loading} />

      {/* Pagination */}
      <Pagination
        dataOnPage={dataOnPage}
        currentPage={currentPage}
        totalPages={Math.ceil(tableData?.data?.pagiantion.pages)}
        tabledata={tableData}
        onPageChange={handlePageChange}
        indexOfFirstItem={indexOfFirstItem}
        itemsPerPage={itemsPerPage}
        indexOfLastItem={indexOfLastItem}
      />
    </section>
  );
};

export default Partners;
