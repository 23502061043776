import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import { app_url } from "../../config";
import { useNavigate } from "react-router-dom";

const AddAccountManager = () => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [fullName, setFullName] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [nationality, setNationality] = useState("");
  const [commissionRate, setCommissionRate] = useState("");
  const [commissionError, setCommissionError] = useState("");
  const [bankName, setBankName] = useState("");
  const [emloymentStatus, setEmloymentStatus] = useState("");
  const [bankAccountTitle, setBankAccountTitle] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [countryOfResidence, setCountryOfResidence] = useState("");
  const [region, setRegion] = useState("");
  const [employmentStatus, setEmploymentStatus] = useState([
    { id: 1, name: "Outsourced" },
    { id: 2, name: "Remote" },
    { id: 3, name: "Permanent" },
    { id: 4, name: "Contractual" },
  ]);
  const [selectedEmploymentStatus, setSelectedEmploymentStatus] = useState("");
  const token = JSON.parse(localStorage.getItem("adminEtijwaal"))?.token;

  // Validate and set FullName on input change
  const handleFullNameChange = (event) => {
    const value = event.target.value;
    setFullName(value);

    // Validation for Full Name
    const isValidFullName = /^[A-Za-z]+(?: [A-Za-z]+)*(?:[-'][A-Za-z]+)?$/.test(
      value
    );
    if (!isValidFullName) {
      setFullNameError("Please enter a valid full name.");
    } else {
      setFullNameError("");
    }
  };

  // Validate and set Email on input change
  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);

    // Validation for Email
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    if (!isValidEmail) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  const handlePasswordChange = (event) => {
    const value = event.target.value;
    const isValidPasswordFormat = /^[a-zA-Z0-9@_\[\]]{8,100}$/.test(value);

    if (!isValidPasswordFormat && value !== "") {
      setPasswordError(
        "Password must be alphanumeric, case-sensitive, no spaces, and may contain @, _, [, ] or ,"
      );
    } else {
      setPasswordError("");
    }

    setPassword(value);
  };

  // Validate and set Phone on input change
  const handlePhoneChange = (value) => {
    setPhone(value);

    // Basic validation for phone numbers (you can expand this logic)
    if (value.length < 10) {
      setPhoneError("Please enter a valid phone number.");
    } else {
      setPhoneError("");
    }
  };

  const handleCommissionChange = (event) => {
    const value = event.target.value;

    // Convert the value to a number for range validation
    const numValue = parseFloat(value);

    // Check if the value matches the pattern of a valid number within the range with up to three decimal places
    const isValidFormat =
      /^(0(\.\d{1,3})?|[1-9][0-9]?(\.\d{1,3})?|99(\.999)?)$/.test(value);

    // Reset error messages
    setCommissionError("");
    if (!isValidFormat) {
      // setCommissionRate(value);
      setCommissionError(
        "Please enter a valid number with exactly 3 decimal places (e.g., 12.340) and between 0 to 99.999."
      );
      return;
    }

    setCommissionRate(value);
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission

    if (
      commissionError ||
      fullNameError ||
      phoneError ||
      emailError ||
      passwordError
    ) {
      toast.error("Please fix the errors before submitting the form.");
      return;
    }
    const data = {
      full_name: fullName,
      email: email,
      password: password,
      employment_status: selectedEmploymentStatus,
      mobile: phone,
      nationality: nationality,
      country_of_residence: countryOfResidence,
      region: region,
      commission_rate: commissionRate,
      bank_name: bankName,
      bank_account_title: bankAccountTitle,
      bank_account_number: bankAccountNumber,
    };
    axios
      .post(`${app_url}/api/partner/registerNewPartner_account_manager`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        toast.success(response?.data?.message);
        setTimeout(() => {
          navigate("/partners");
        }, 500);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            toast.error(error.response.data.message);
          } else if (error.response.status === 401) {
            toast.error(error.response.data.message);
            navigate("/");
          } else if (error.response.status === 500) {
            toast.error(error.response.data.message);
          } else {
            toast.error(error.response.data.message);
          }
        } else {
          toast.error(
            error?.response?.data?.message ||
              "Network error. Please check your internet connection."
          );
        }
        console.error("Error:", error.response);
      });
  };

  return (
    <section className="wrapper">
      <div className="d-flex flex-wrap gap-3 justify-content-between">
        <div className="d-flex justify-content-between align-items-center w-100">
          <p className="heading mb-0">
            <i
              className="bi bi-arrow-left-circle-fill fs-3 me-3 my-auto pointer"
              onClick={() => window.history.back()}
            ></i>
            Add Account Manager
          </p>
        </div>
      </div>
      <form className="" onSubmit={handleSubmit}>
        {/* Personal Info */}
        <div className="mt-4">
          <p className="fw-bold fs-4 mb-0">Personal Info</p>
          <div className="row mt-sm-3 mt-0">
            <div className="col-sm-6 col-12 mt-sm-0 mt-3">
              <label htmlFor="fullName" className="form-label">
                Full Name*
              </label>
              <input
                type="text"
                className="form-control"
                id="fullName"
                onChange={handleFullNameChange}
                placeholder="Enter Full Name"
                required
                maxLength={20}
              />
              {fullNameError && (
                <div className="text-danger mt-1">{fullNameError}</div>
              )}
            </div>
            <div className="col-sm-6 col-12 mt-sm-0 mt-3">
              <label htmlFor="password" className="form-label">
                Password*
              </label>
              <input
                type="password"
                className="form-control"
                id="password"
                onChange={handlePasswordChange}
                placeholder="Enter Password"
                required
                maxLength={100}
              />
              {passwordError && (
                <div className="text-danger mt-1 ">{passwordError}</div>
              )}
            </div>
          </div>
          <div className="row mt-sm-3 mt-0">
            <div className="col-sm-6 col-12 mt-sm-0 mt-3">
              <label htmlFor="email" className="form-label">
                Email*
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                onChange={handleEmailChange}
                placeholder="Enter Email"
                required
                maxLength={100}
              />
              {emailError && (
                <div className="text-danger mt-1">{emailError}</div>
              )}
            </div>
            <div className="col-sm-6 col-12 mt-sm-0 mt-3">
              <label htmlFor="nationality" className="form-label">
                Nationality*
              </label>
              <input
                type="text"
                className="form-control"
                id="nationality"
                onChange={(e) => setNationality(e.target.value)}
                placeholder="Enter Nationality"
                required
                maxLength={100}
              />
            </div>
          </div>
          <div className="row mt-sm-3 mt-0">
            <div className="col-sm-6 col-12 mt-sm-0 mt-3 d-flex flex-column">
              <label htmlFor="phoneNumber" className="form-label">
                Phone Number
              </label>
              <PhoneInput
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                }}
                country={"us"} // Default country code
                value={phone}
                onChange={handlePhoneChange}
              />
              {phoneError && (
                <div className="text-danger mt-1">{phoneError}</div>
              )}
            </div>
            <div className="col-sm-6 col-12 mt-sm-0 mt-3">
              <label htmlFor="region" className="form-label">
                Region
              </label>
              <input
                type="text"
                className="form-control"
                id="region"
                placeholder="Enter Region"
                required
                onChange={(e) => setRegion(e.target.value)}
                maxLength={30}
              />
            </div>
          </div>
          <div className="row mt-sm-3 mt-0">
            <div className="col-sm-6 col-12 mt-sm-0 mt-3 d-flex flex-column">
              <label htmlFor="countryResidence" className="form-label">
                Country of Residence
              </label>
              <input
                type="text"
                className="form-control"
                id="countryResidence"
                placeholder="Enter Country Residence"
                onChange={(e) => setCountryOfResidence(e.target.value)}
                maxLength={30}
              />
            </div>
            <div className="col-sm-6 col-12 mt-sm-0 mt-3 d-flex flex-column">
              <label htmlFor="employmentStatus" className="form-label">
                Employment Status*
              </label>
              <select
                className="border rounded px-2 py-1"
                onChange={(e) => setSelectedEmploymentStatus(e.target.value)}
                required
              >
                <option value="" disabled selected>
                  Select Emplyment
                </option>
                {employmentStatus?.map((emp) => (
                  <option key={emp?.id} value={emp?.name}>
                    {emp?.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row mt-sm-3 mt-0">
            <div className="col-sm-6 col-12 mt-sm-0 mt-3">
              <label htmlFor="commissionRate" className="form-label">
                Comission Rate*
              </label>
              <div style={{ position: "relative" }}>
                <input
                  type="text"
                  className="form-control"
                  id="commissionRate"
                  placeholder="Enter commission rate"
                  onChange={handleCommissionChange}
                  required
                  style={{ paddingRight: "22px" }}
                />
                <span
                  style={{ position: "absolute", right: "10px", bottom: "5px" }}
                >
                  %
                </span>
              </div>
              {commissionError && (
                <div className="text-danger mt-1">{commissionError}</div>
              )}
            </div>
          </div>

          {/* Bank Info */}
          <div className="mt-4">
            <p className="fw-bold fs-4 mb-0">Bank Information</p>
            <div className="row mt-sm-3 mt-0">
              <div className="col-sm-6 col-12 mt-sm-0 mt-3">
                <label htmlFor="bankName" className="form-label">
                  Bank Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="bankName"
                  onChange={(e) => setBankName(e.target.value)}
                  placeholder="Enter bank name"
                  maxLength={100}
                />
              </div>

              <div className="col-sm-6 col-12 mt-sm-0 mt-3">
                <label htmlFor="bankAccountTitle" className="form-label">
                  Bank Account Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="bankAccountTitle"
                  onChange={(e) => setBankAccountTitle(e.target.value)}
                  placeholder="Enter bank account title"
                  maxLength={100}
                />
              </div>
            </div>
            <div className="row mt-sm-3 mt-0">
              <div className="col-sm-6 col-12 mt-sm-0 mt-3">
                <label htmlFor="bankAccountNumber" className="form-label">
                  Bank Account Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="bankAccountNumber"
                  onChange={(e) => setBankAccountNumber(e.target.value)}
                  placeholder="Enter bank account number"
                  maxLength={100}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-100 d-flex justify-content-center">
          <button type=" submit" className="btn primary-btn mt-4 px-4">
            Submit
          </button>
        </div>
      </form>
    </section>
  );
};

export default AddAccountManager;
