export default function Error500({ message }) {
  return (
    <>
      <div
        className="alert alert-danger"
        role="alert"
        style={{ height: "100vh" }}
      >
        <p style={{ fontSize: "42px" }} className="text-danger">
          {message || "Network Error"}!
        </p>
        <p className="mb-0 text-danger">
          If your Network Connection is working, try reloading this page.
        </p>
      </div>
    </>
  );
}
