import React, { useEffect, useState, useCallback } from "react";
import { Bar } from "react-chartjs-2";
import { app_url } from "../../config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import debounce from "lodash.debounce";
import Loader from "../Loader";

const Leadsales = () => {
  const token = JSON.parse(localStorage.getItem("adminEtijwaal"))?.token;

  const getLastFiveYears = () => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: 5 }, (_, i) => currentYear - i);
  };

  const [selectedYear, setSelectedYear] = useState(null);
  const [years, setYears] = useState(getLastFiveYears());
  const [isLoading, setIsLoading] = useState(true);
  const [leadSales, setLeadSales] = useState([]);
  const [months, setMonths] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  const fetchData = useCallback(
    async (year) => {
      setIsLoading(true);
      try {
        const res = await axios.get(
          `${app_url}/api/dashboard/getAdminDashboardLeadSales?year=${year}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setLeadSales(res?.data?.leadSales?.monthlySales || []);
        setMonths(res?.data?.leadSales?.months || []);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        if (err.response) {
          if (err.response.status === 400) {
            toast.error(err.response.data.message || "Bad Request");
          } else if (err.response.status === 401) {
            navigate("/");
          } else if (err.response.status === 500) {
            toast.error(err.response.data.message || "Internal Server Error");
          } else {
            toast.error("An unexpected error occurred.");
          }
        } else {
          toast.error("Network error. Please check your internet connection.");
        }
      }
    },
    [token, navigate]
  );

  useEffect(() => {
    setSelectedYear(years[0]);
  }, [years]);

  useEffect(() => {
    if (selectedYear !== null) {
      fetchData(selectedYear);
    }
  }, [selectedYear, fetchData]);

  useEffect(() => {
    const handleResize = debounce(() => setWidth(window.innerWidth), 500);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const media = width >= 0 && width <= 758;
  const data = {
    labels:
      months.length > 0
        ? months
        : ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: `Total Sales USD`,
        data: leadSales.length > 0 ? leadSales : Array(12).fill(0),
        backgroundColor: [
          "gray",
          "#ED8936",
          "gray",
          "#ED8936",
          "gray",
          "#ED8936",
          "gray",
        ],
        borderColor: "transparent",
        borderRadius: 5,
      },
    ],
  };

  return (
    <>
      <div className="my-4">
        {years.map((year) => (
          <button
            key={year}
            onClick={() => setSelectedYear(year)}
            className={
              selectedYear === year ? "btn ms-0 dark-btn" : "btn ms-0 chart-btn"
            }
            bg="transparent"
          >
            {year}
          </button>
        ))}
      </div>
      {isLoading ? (
        <div style={{ margin: "10em 0" }}>
          <Loader />
        </div>
      ) : (
        <Bar
          data={data}
          options={{
            plugins: {
              legend: { display: false },
            },
            responsive: true,
            scales: {
              x: { grid: { display: false } },
              y: {
                beginAtZero: false,
                grid: { drawTicks: false },
                ticks: {
                  stepSize: 100,
                  callback: (value) => `$${value}`,
                },
              },
            },
            aspectRatio: media ? 1.5 : 3,
          }}
        />
      )}
    </>
  );
};

export default Leadsales;
