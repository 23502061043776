import React, { useEffect, useRef, useState } from "react";
import { FaDollarSign, FaUser } from "react-icons/fa";
import axios from "axios";
import { app_url } from "../../config";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import Error500 from "../../components/500/Error500";
import {
  MdOutlineSimCardAlert,
  MdOutlineSimCardDownload,
} from "react-icons/md";
import GeoChart from "../../components/charts/GeoChart";
import ChartOne from "../../components/charts/ChartOne";
import Leadsales from "../../components/charts/Leadsales";
import { DateRangePicker } from "react-date-range";
import { addDays, setSeconds, setHours, setMinutes } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { formatDate } from "../../formatDate/formatDate";
import PartnerDiscountsModal from "../../components/modals/PartnerDiscountsModal";
import { VscVmActive } from "react-icons/vsc";
import { FcExpired } from "react-icons/fc";
import TopBundlesTable from "../../components/tables/TopBundlesTable";
import PartnerLeadsales from "../../components/charts/PartnerLeadSales";
import { FaEdit } from "react-icons/fa";

const PartnerDetails = () => {
  const token = JSON.parse(localStorage.getItem("adminEtijwaal"))?.token;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [networkIssue, setNetworkIssue] = useState(false);
  const [partnerData, setPartnerData] = useState({});
  const [discountPlan, setDiscountPlan] = useState([]);
  const [socialMediaLinks, setSocialMediaLinks] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const datePickerRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [esimsData, setEsimsData] = useState([]);
  const [updateDiscountPlan, setUpdateDiscountPlan] = useState([]);
  const [selectedDiscountPlanUpdate, setSelectedDiscountPlanUpdate] =
    useState(null);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalShow = () => setShowModal(true);

  /* Date Range Picker */
  function getLastDayOfMonth(year, month) {
    const nextMonth = month + 1;
    const date = new Date(year, nextMonth, 0);
    return date.getDate();
  }
  const month = new Date().getMonth();
  const year = new Date().getFullYear();

  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  const getStartDateForInitialState = () => {
    const actualDate = addDays(new Date(), +1 - new Date().getDate());
    const adjustedStartDate = setSeconds(
      setMinutes(setHours(actualDate, 0), 0),
      0
    );
    return adjustedStartDate;
  };

  const [state, setState] = useState([
    {
      startDate: getStartDateForInitialState(),
      endDate: new Date(year, month, getLastDayOfMonth(year, month)),
      key: "selection",
    },
  ]);

  //update dateFrom and dateTo based on state changes
  useEffect(() => {
    const formattedStartDate = formatDate(state[0].startDate);
    const formattedEndDate = formatDate(state[0].endDate);

    setdateFrom(formattedStartDate);
    setdateTo(formattedEndDate);
  }, [state]);
  /* Date Range Picker end*/

  // Date Picker outside click handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setShowDatePicker(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  /* new Date Picker Data */
  const handleSelect = (ranges) => {
    const { startDate, endDate } = ranges.selection;
    const adjustedEndDate = setSeconds(
      setMinutes(setHours(endDate, 23), 59),
      59
    );
    const adjustedStartDate = setSeconds(
      setMinutes(setHours(startDate, 0), 0),
      0
    );
    setState([
      {
        startDate: adjustedStartDate,
        endDate: adjustedEndDate,
        key: "selection",
      },
    ]);
  };

  const [dateFrom, setdateFrom] = useState(formatDate(state[0].startDate));
  const [dateTo, setdateTo] = useState(formatDate(state[0].endDate));

  useEffect(() => {
    axios
      .get(`${app_url}/api/partner/getDiscountPlans_for_partner`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setUpdateDiscountPlan(response?.data?.discountPlans);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            toast.error(error.response.data.message);
            navigate("/");
          } else if (error.response.status === 500) {
            toast.error(error.response.data.message);
          } else {
            toast.error(error.response.data.message);
          }
        } else {
          setNetworkIssue(true);
          toast.error("Network error. Please check your internet connection.");
          setError("Network error. Please check your internet connection.");
        }
      });
  }, []);

  console.log(updateDiscountPlan, "discountPlan check");

  const handlePasswordChange = (event) => {
    const value = event.target.value;

    const isValidPasswordFormat = /^[a-zA-Z0-9@_\[\].,]{8,}$/.test(value);

    if (!isValidPasswordFormat && value !== "") {
      setPasswordError(
        "Password must be alphanumeric, case-sensitive, no spaces, and may contain @, _, [, ] or ,"
      );
    } else {
      setPasswordError("");
    }
    setPassword(value);
    setInputValue(value);
  };

  const handleChangeDiscountPlan = (selectedPlan) => {
    console.log("Selected Plan:", selectedPlan); // Add this for debugging
    setSelectedDiscountPlanUpdate(selectedPlan);
    updateUserdetail("discount_plan", selectedPlan);
  };

  const updateUserdetail = (slug, updatedPlan = selectedDiscountPlanUpdate) => {
    console.log("Updating user detail for:", slug);
    if (updatedPlan || password) {
      const data = {
        partner_id: partnerData?.id,
        slug: slug === "password" ? "password" : "discount_plan",
        dplan_id: updatedPlan,
        password: password,
      };
      axios
        .post(
          `${app_url}/api/partner/update_partner_values_by_id
 `,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message);
          window.location.reload();
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              toast.error(error.response.data.message);
              navigate("/");
            } else if (error.response.status === 500) {
              toast.error(error.response.data.message);
            } else {
              toast.error(error.response.data.message);
            }
          } else {
            setNetworkIssue(true);
            toast.error(
              "Network error. Please check your internet connection."
            );
            setError("Network error. Please check your internet connection.");
          }
        });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    axios
      .get(`${app_url}/api/partner/get_partner_by_id?id=${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setDiscountPlan(res?.data?.dplan_slabs);
        const data = res?.data?.partner;
        setPartnerData(data);
        const links = data?.social_media_links;
        if (links) {
          try {
            setSocialMediaLinks(JSON.parse(links));
          } catch (error) {
            console.error("Error parsing social media links:", error);
            toast.error("Failed to parse social media links.");
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          if (err.response.status === 400)
            toast.error(err.response.data.message || "Bad Request");
          else if (err.response.status === 401) navigate("/");
          else if (err.response.status === 500)
            toast.error("Internal Server Error");
        } else {
          setNetworkIssue(true);
          toast.error("Network error. Please check your internet connection.");
          setError("Network error. Please check your internet connection.");
        }
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (partnerData?.id) {
      axios
        .get(
          `${app_url}/api/dashboard/getAdminPartnerDashboardDetails_byId?from=${dateFrom}&to=${dateTo}&id=${partnerData?.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setEsimsData(res?.data?.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response) {
            const status = err.response.status;
            if (status === 400) {
              toast.error(err.response.data.message || "Bad Request");
            } else if (status === 401) {
              navigate("/");
            } else if (status === 500) {
              toast.error(err.response.data.message || "Internal Server Error");
            } else {
              toast.error("An unexpected error occurred.");
            }
          } else {
            setNetworkIssue(true);
            toast.error(
              "Network error. Please check your internet connection."
            );
            setError("Network error. Please check your internet connection.");
          }
        });
    }
  }, [dateFrom, dateTo, partnerData?.id]);

  if (networkIssue) {
    return <Error500 message={error} />;
  }

  return (
    <section className="wrapper overflow-hidden">
      <div>
        <div className="d-flex justify-content-between align-items-center w-100 gap-2 flex-wrap">
          <div>
            <p className="heading">
              <i
                className="bi bi-arrow-left-circle-fill fs-3 me-3 my-auto pointer text-capitalize"
                onClick={() => window.history.back()}
              ></i>
              {partnerData?.agent_name}
            </p>
          </div>
          <p className="heading">eTijwaal Partner Center</p>
        </div>
        <div style={{ width: "12em" }}>
          <div class="shadow-sm d-flex align-items-center bg-white p-1 p-sm-2 rounded">
            <svg
              stroke="currentColor"
              fill="red"
              stroke-width="0"
              viewBox="0 0 24 24"
              className="d-inline-block align-middle text-danger me-2 custom-icon"
              style={{ width: "25px", height: "25px" }}
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path d="M21 18v1c0 1.1-.9 2-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h14c1.1 0 2 .9 2 2v1h-9a2 2 0 00-2 2v8a2 2 0 002 2h9zm-9-2h10V8H12v8zm4-2.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z"></path>
            </svg>
            {partnerData?.balance > 10 ? (
              <h2 class="text-success fs-5 fw-semibold mb-0">
                <span class=" fs-6 fw-light text-success">Balance:</span> $
                {partnerData?.balance}
              </h2>
            ) : (
              <h2 class="text-danger fs-5 fw-semibold mb-0">
                <span class=" fs-6 fw-light text-danger">Balance:</span> $
                {partnerData?.balance}
              </h2>
            )}
          </div>
        </div>
      </div>

      <div className="row my-3">
        {/* Partner Details */}
        <div className="col-md-6">
          <div className="card shadow-sm">
            <div className="card-body">
              <ul className="list-group">
                {/* <div className="card-body"> */}
                <li className="list-group-item">
                  <strong>Username:</strong> {partnerData?.username}
                </li>
                <li className="list-group-item">
                  <strong>Email:</strong> {partnerData?.email}
                </li>
                <li className="list-group-item">
                  <strong>Mobile:</strong> {partnerData?.mobile}
                </li>
                <li className="list-group-item">
                  <strong>Company Name:</strong> {partnerData?.company_name}
                </li>
                <li className="list-group-item">
                  <strong>Address:</strong> {partnerData?.address}
                </li>

                <li className="list-group-item d-sm-flex justify-content-between align-items-center gap-3">
                  <div className="d-flex gap-2 flex-wrap align-items-center">
                    <div>
                      <strong>Discount Plan:</strong> {partnerData?.dplan_name}{" "}
                      ({partnerData?.dplan_description})
                    </div>
                    <select
                      onChange={(e) => {
                        handleChangeDiscountPlan(Number(e.target.value));
                        // updateUserdetail("discount_plan");
                      }}
                      className="border rounded"
                      style={{ width: 190 }}
                    >
                      <option value="" disabled selected>
                        Update Discount Plan
                      </option>
                      {updateDiscountPlan?.map((plan) => (
                        <option key={plan?.id} value={plan?.id}>
                          {plan?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="d-flex justify-content-end mt-sm-0 mt-2">
                    <button
                      type=" submit"
                      className="btn primary-btn "
                      onClick={handleModalShow}
                    >
                      Details
                    </button>
                  </div>
                </li>
                <li className="list-group-item">
                  <strong>Account Mode:</strong> {partnerData?.account_mode}
                </li>
                <li className="list-group-item">
                  <strong>Status:</strong>{" "}
                  {partnerData?.agent_status ? "Active" : "Inactive"}
                </li>
                <li className="list-group-item">
                  <strong>Joined:</strong>{" "}
                  {new Date(partnerData?.created_at).toLocaleDateString()}
                </li>
                <li className="d-flex gap-3 list-group-item ">
                  <div className="">
                    <div className="d-flex align-items-center gap-2">
                      <strong>Password:</strong>
                      {/* Input Field */}
                      <div className="d-flex align-items-center position-relative">
                        <input
                          type="password"
                          value={password}
                          id="password"
                          onChange={handlePasswordChange}
                          disabled={!isEditable}
                          placeholder="******"
                          style={{
                            padding: "8px",
                            border: isEditable
                              ? "1px solid #000"
                              : "1px dashed #ccc",
                            backgroundColor: isEditable ? "#fff" : "#f9f9f9",
                            borderRadius: "4px",
                            outline: "none",
                          }}
                          required
                          maxLength={100}
                        />
                        {/* Edit Icon */}
                        {!isEditable && (
                          <button
                            type="button"
                            onClick={() => setIsEditable(true)}
                            className="btn primary-btn px-1 py-0 position-absolute "
                            style={{ right: "5px" }}
                          >
                            <FaEdit />
                          </button>
                        )}
                      </div>
                    </div>

                    {passwordError && (
                      <div
                        className="text-danger mt-1 "
                        style={{ maxWidth: "275px" }}
                      >
                        {passwordError}
                      </div>
                    )}
                  </div>

                  {/* Save Button */}
                  <div>
                    <button
                      type="button"
                      onClick={() => {
                        // console.log("Save button clicked");
                        updateUserdetail("password");
                      }}
                      disabled={!isEditable || !inputValue}
                      style={{
                        padding: "8px 16px",
                        backgroundColor:
                          isEditable && inputValue && !passwordError
                            ? "#007bff"
                            : "#ccc",
                        color: "#fff",
                        border: "none",
                        borderRadius: "4px",
                        cursor:
                          isEditable && inputValue && !passwordError
                            ? "pointer"
                            : "not-allowed",
                      }}
                    >
                      Save
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Social Media Links */}
        <div className="col-md-6">
          <div className="card shadow-sm" style={{ height: "32.7em" }}>
            <div>
              <div className="card-body">
                {socialMediaLinks?.length > 0 ? (
                  <>
                    <ul
                      className="list-group"
                      style={{ maxHeight: "30.7em", overflowY: "scroll" }}
                    >
                      <li className="list-group-item">
                        <strong>Customer Portal Url:</strong>{" "}
                        {partnerData?.cust_portal_url ? (
                          <a
                            href={partnerData?.cust_portal_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {partnerData?.cust_portal_url}
                          </a>
                        ) : (
                          "Not Provided"
                        )}
                      </li>
                      {socialMediaLinks.map((link, index) => (
                        <li
                          key={index}
                          className="list-group-item"
                          // style={{ padding: "13.45px 5px" }}
                        >
                          <strong>{link.name}:</strong>{" "}
                          {link.url ? (
                            <a
                              href={link.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {link.url}
                            </a>
                          ) : (
                            "Not Provided"
                          )}
                        </li>
                      ))}
                    </ul>
                  </>
                ) : (
                  <p>No social media links available.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-xl-flex px-3 py-3 justify-content-xl-between table-header-item mb-2">
        {/* new Date Picker Data */}
        <div className="dropdown d-flex flex-wrap gap-3" ref={datePickerRef}>
          <button
            className="btn btn-white border dropdown-toggle"
            type="button"
            onClick={toggleDatePicker}
          >
            Select Date
          </button>
          <div className="d-flex align-items-center gap-1 dropdown">
            <div className="order-date">
              <span className="pe-2">From:</span>
              <button
                type="button"
                disabled
                className="text-black btn btn-white border "
              >
                {dateFrom}
              </button>
            </div>
            <div className="order-date">
              <span className="pe-2">To:</span>
              <button
                type="button"
                disabled
                className="text-black btn btn-white border "
              >
                {dateTo}
              </button>
            </div>
          </div>
          {showDatePicker && (
            <ul
              // aria-labelledby="dropdownMenuClickableInside"
              className="dropdown-menu px-2 py-0 show"
              style={{ top: "46px", left: "-20px" }}
            >
              <li className="my-2 dropdown-item">
                <DateRangePicker
                  onChange={handleSelect}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={state}
                  direction="horizontal"
                />
              </li>
            </ul>
          )}
        </div>
      </div>

      <div className="row ">
        <div className="col-lg-3 col-sm-6 my-3">
          <div className="card w-100">
            <div className="card-body pointer d-flex justify-content-between align-items-center">
              <div>
                <p className="fs-5">Total Sales </p>
                <p className="heading m-0 text-black">
                  {esimsData?.eSim_data?.length > 0
                    ? esimsData?.eSim_data[0]?.total_sales.toFixed(2)
                    : "0.00"}
                </p>
              </div>
              <div>
                <div className="back-secondary rounded-3 px-2 py-1">
                  <FaDollarSign size={30} fill="#fff" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-sm-6 my-3">
          <div className="card w-100">
            <div className="card-body pointer d-flex justify-content-between align-items-center">
              <div>
                <p className="fs-5">Total eSIM Sold</p>
                <p className="heading m-0 text-black">
                  {esimsData?.eSim_data?.length > 0
                    ? esimsData?.eSim_data[0]?.esim_sold
                    : "0"}
                </p>
              </div>
              <div>
                <div className="back-secondary rounded-3 px-2 py-1">
                  <MdOutlineSimCardDownload size={30} fill="#fff" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-sm-6 my-3">
          <div className="card w-100">
            <div className="card-body pointer d-flex justify-content-between align-items-center">
              <div>
                <p className="fs-5">Total Topup Sold</p>
                <p className="heading m-0 text-black">
                  {esimsData?.eSim_data?.length > 0
                    ? esimsData?.eSim_data[0]?.esim_top_sold
                    : "0"}
                </p>
              </div>
              <div>
                <div className="back-secondary rounded-3 px-2 py-1">
                  <MdOutlineSimCardDownload size={30} fill="#fff" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-sm-6 my-3">
          <div className="card w-100">
            <div className="card-body pointer d-flex justify-content-between align-items-center">
              <div>
                <p className="fs-5">Customer Registered</p>
                <p className="heading m-0 text-black">
                  {esimsData?.customer_registration_count?.length > 0
                    ? esimsData?.customer_registration_count[0]?.customers
                    : "0"}
                </p>
              </div>
              <div>
                <div className="back-secondary rounded-3 px-2 py-1">
                  <FaUser size={30} fill="#fff" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row ">
        <div className="col-lg-4 col-sm-6 my-3">
          <div className="card w-100">
            <div className="card-body pointer d-flex justify-content-between align-items-center">
              <div>
                <p className="fs-5">active eSIMS </p>
                <p className="heading m-0 text-black">
                  {esimsData?.lineStatus?.length > 0
                    ? esimsData?.lineStatus[0]?.active_esims
                    : "0"}
                </p>
              </div>
              <div>
                <div className="back-secondary rounded-3 px-2 py-1">
                  <VscVmActive size={30} fill="#fff" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-sm-6 my-3">
          <div className="card w-100">
            <div className="card-body pointer d-flex justify-content-between align-items-center">
              <div>
                <p className="fs-5">Inactive eSIMS</p>
                <p className="heading m-0 text-black">
                  {esimsData?.lineStatus?.length > 0
                    ? esimsData?.lineStatus[0]?.inactive_esims
                    : "0"}
                </p>
              </div>

              <div>
                <div className="back-secondary rounded-3 px-2 py-1">
                  <i className="bi bi-sim-slash text-white fs-4"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-sm-6 my-3">
          <div className="card w-100">
            <div className="card-body pointer d-flex justify-content-between align-items-center">
              <div>
                <p className="fs-5">Expired Finished eSIMS</p>
                <p className="heading m-0 text-black">
                  {esimsData?.lineStatus?.length > 0
                    ? esimsData?.lineStatus[0]?.expired_finished_esims
                    : "0"}
                </p>
              </div>
              <div>
                <div className="back-secondary rounded-3 px-2 py-1">
                  <FcExpired size={30} fill="#fff" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* TOP BUNDLES */}
      <div className="row my-5">
        <div className="col-md-8">
          <div className="card border-0 h-100 ">
            <div className="card-body">
              <h1 className="pb-4 heading border-bottom mb-4">
                Top Bundles Sold (eSIM/Topup)
              </h1>

              <div className="">
                <TopBundlesTable tabledata={esimsData} isLoading={isLoading} />
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card border-0 h-100">
            <div className="card-body">
              <h2 className="pb-4 heading border-bottom mb-4">
                Country Metrics (eSIMS)
              </h2>
              {isLoading ? (
                <p className="fs-5">Loading...</p>
              ) : esimsData?.countryMetrics?.length === 0 ? (
                <p className="fs-5">No data found</p>
              ) : (
                <div
                  className="d-flex flex-column gap-3"
                  style={{
                    maxHeight: "41em",
                    overflow: "scroll",
                  }}
                >
                  {esimsData?.countryMetrics?.map((item, index) => {
                    const firstPackageCount =
                      esimsData?.countryMetrics[0]?.package_count || 0;
                    return (
                      <>
                        <div
                          className="align-items-center justify-content-between gap-2"
                          key={index}
                        >
                          <div
                            style={{ width: "100%", marginBottom: "5px" }}
                            className="fs-4 d-flex align-items-center"
                          >
                            {item?.flagImage_1 && (
                              <img
                                src={app_url + item?.flagImage_1}
                                alt=""
                                width={30}
                                className="me-2"
                              />
                            )}
                            <div className="d-flex justify-content-between w-100">
                              <span className="fs-5">
                                {item?.category.replace(/-/g, " ")}
                              </span>
                              <span className="fs-5 m-1">
                                {item?.package_count}
                              </span>
                            </div>
                          </div>
                          <div
                            className="progress"
                            style={{
                              width: "100%",
                            }}
                          >
                            <div
                              className="progress-bar progress-bar-striped progress-bar-animated"
                              role="progressbar"
                              aria-label="country metrices"
                              aria-valuenow={item?.package_count}
                              aria-valuemin="0"
                              aria-valuemax={firstPackageCount}
                              style={{
                                width: `${
                                  (item.package_count / firstPackageCount) * 100
                                }%`,
                              }}
                            ></div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row mx-0">
        <div className="col-md-8 w-100 mt-2 px-0">
          <div className="card border-0 h-100">
            <div className="card-body">
              <p className="heading">Summarize Sales</p>
              <hr />
              <PartnerLeadsales />
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <PartnerDiscountsModal
          handleModalClose={handleModalClose}
          discountPlan={discountPlan}
        />
      )}
    </section>
  );
};

export default PartnerDetails;
