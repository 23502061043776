import React from "react";

const SocialMediaLinksModal = ({
  handleModalClose,
  handleUrlChange,
  tempLinks,
  handleSaveLinks,
  isSaveButtonEnabled,
}) => {
  return (
    <>
      <div className="modal-backdrop fade show"></div>
      <div className="modal show d-block" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Social Media Links</h5>
              <button
                type="button"
                className="btn-close"
                onClick={handleModalClose}
              ></button>
            </div>
            <div className="modal-body">
              {tempLinks.map((link, index) => (
                <div key={link.slug} className="mb-3">
                  <label className="form-label">{link.name}</label>
                  <input
                    type="url"
                    className="form-control"
                    placeholder={`Enter ${link.name} URL`}
                    value={link.url}
                    onChange={(e) => handleUrlChange(index, e)}
                  />
                  {link.error && (
                    <div className="text-danger mt-1">{link.error}</div>
                  )}
                </div>
              ))}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSaveLinks}
                disabled={!isSaveButtonEnabled}
              >
                Save Links
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialMediaLinksModal;
