// pages/notifications/template/[slug].js;
import axios from "axios";
import React, { useEffect, useState } from "react";
import { app_url } from "../../config";
import Error500 from "../../components/500/Error500";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

const ViewTemplate = () => {
  const { id } = useParams();
  const [editing, setEditing] = useState({ title: false, body: false });
  const [template, setTemplate] = useState(null);
  const [online, setOnline] = useState(navigator.onLine);
  const [errMsg, , setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState({
    title: "",
    body: "",
    image: "",
  });

  const token = JSON.parse(localStorage.getItem("adminEtijwaal"))?.token;
  const navigate = useNavigate();

  // console.log(id, "view tamplate id check");
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${app_url}/api/notification/getFcmTemplateById?id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setTemplate(res.data.template[0]);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response) {
          if (err.response.status === 400) {
            toast.error(err.response.data.message || "Bad Request");
          } else if (err.response.status === 401) {
            navigate("/");
          } else if (err.response.status === 500) {
            toast.error(err.response.data.message || "Internal Server Error");
          } else {
            toast.error("An unexpected error occurred.");
          }
        } else {
          setOnline(navigator.onLine);
          toast.error("Network error. Please check your internet connection.");
          setErrMsg("Network error. Please check your internet connection.");
        }
      });
  }, [id]);

  if (!online) {
    return <Error500 message={errMsg} />;
  }

  return (
    <div className="wrapper">
      <section>
        <div className="d-flex align-items-center gap-2">
          <i
            className="bi bi-arrow-left-circle-fill fs-3 my-auto pointer"
            onClick={() => window.history.back()}
          ></i>
          <p className="heading" style={{ marginBottom: 0 }}>
            Edit Notification Template
          </p>
        </div>
        <div className="container mt-5">
          <div className="form-row">
            <div className="mb-3">
              {/* <label className='mb-2' for="title">Name</label> */}
              <div className="d-flex gap-2">
                <input
                  type="text"
                  maxLength={30}
                  className="form-control border-bottom rounded-0 border-0 shadow-0 bg-transparent"
                  readOnly
                  id="Name"
                  name="name"
                  value={template?.name}
                  required
                />
                <button disabled className="btn btn-sm border-0">
                  <i className="bi bi-file-earmark-lock2-fill fs-3"></i>
                </button>
              </div>
            </div>
            <div className="mb-3">
              {/* <label className='mb-2' for="title">Title</label> */}
              <div className="d-flex gap-2">
                <div className="position-relative w-100">
                  <input
                    className="form-control border-bottom rounded-0 border-0 shadow-0 bg-transparent"
                    type="text"
                    value={template?.title}
                    onChange={(e) =>
                      setDetails({ ...details, title: e.target.value })
                    }
                    maxLength={30}
                    disabled={editing.title ? false : true}
                    id="title"
                    name="title"
                    placeholder="Enter Title"
                    required
                  />
                  <p
                    className="small"
                    style={{ position: "absolute", right: "0", top: "6px" }}
                  >
                    24 / 30
                  </p>
                </div>
                {editing.title && !editing.body ? (
                  <div className="d-flex gap-2">
                    <button
                      onClick={() => {
                        setEditing({ ...editing, body: false, title: false });
                      }}
                      className="btn border btn-sm"
                    >
                      <i className="bi bi-check2 fs-3 text-success"></i>
                    </button>
                    <button
                      onClick={() => {
                        setEditing({ ...editing, body: false, title: false });
                      }}
                      className="btn border btn-sm"
                    >
                      <i className="bi bi-x fs-3 text-danger"></i>
                    </button>
                  </div>
                ) : (
                  <button
                    onClick={() => {
                      setEditing({ ...editing, body: false, title: true });
                    }}
                    className="btn btn-sm d-flex align-self-end"
                  >
                    <i className="bi bi-pencil-square fs-3"></i>
                  </button>
                )}
              </div>
            </div>
            <div className="mb-3">
              {/* <label className='mb-2' for="description">Body</label> */}
              <div className="d-flex gap-2">
                <div className="position-relative w-100">
                  <textarea
                    onChange={(e) =>
                      setDetails({ ...details, body: e.target.value })
                    }
                    cols={12}
                    maxLength={255}
                    value={template?.body}
                    className="form-control border-bottom rounded-0 border-0 shadow-0 bg-transparent"
                    name="description"
                    readOnly
                    id="description"
                    rows={5}
                    style={{ resize: "none" }}
                  />
                  <p
                    className="small"
                    style={{ position: "absolute", right: "0", top: "6px" }}
                  >
                    10 / 255
                  </p>
                </div>
                {editing.body && !editing.title ? (
                  <div className="d-flex align-items-end gap-2">
                    <button
                      onClick={() => {
                        setEditing({ ...editing, body: false, title: false });
                      }}
                      className="btn border btn-sm d-flex align-self-end"
                    >
                      <i className="bi bi-check2  fs-3 text-success"></i>
                    </button>
                    <button
                      onClick={() => {
                        setEditing({ ...editing, body: false, title: false });
                      }}
                      className="btn border btn-sm d-flex align-self-end"
                    >
                      <i className="bi bi-x fs-3 text-danger"></i>
                    </button>
                  </div>
                ) : (
                  <button
                    onClick={() => {
                      setEditing({ ...editing, body: true, title: false });
                    }}
                    className="btn btn-sm d-flex align-self-end"
                  >
                    <i className="bi bi-pencil-square fs-3"></i>
                  </button>
                )}
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-3">
              <label className="mb-2">Change Image</label>
              <input
                className="form-control"
                type="file"
                id="Upload-Img"
                accept="image/png"
              />
              <div className="mt-2 d-flex align-items-center">
                {template?.image ? (
                  <img
                    src={app_url + template?.image}
                    width={100}
                    className="shadow p-2 h-100 rounded"
                    alt="Template Image"
                  />
                ) : (
                  <span>No Template Image</span>
                )}
                <ul className="mb-0">
                  <li className="text-sm">
                    Image should be less than <b>1MB</b>
                  </li>
                  <li className="text-sm">
                    Format Should be <b>PNG</b>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ViewTemplate;
