import React, { useEffect, useRef, useState } from "react";
import { FaDollarSign, FaShoppingCart } from "react-icons/fa";
import axios from "axios";
import { app_url } from "../../config";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import Error500 from "../../components/500/Error500";
import {
  MdOutlineSimCardAlert,
  MdOutlineSimCardDownload,
} from "react-icons/md";
import GeoChart from "../../components/charts/GeoChart";
import ChartOne from "../../components/charts/ChartOne";
import Leadsales from "../../components/charts/Leadsales";
import { DateRangePicker } from "react-date-range";
import { addDays, setSeconds, setHours, setMinutes } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { formatDate } from "../../formatDate/formatDate";

const PartnerDetails = () => {
  const token = JSON.parse(localStorage.getItem("adminEtijwaal"))?.token;
  const [loading, setLoading] = useState(false);
  const [failed, setFailed] = useState("");
  const [networkIssue, setNetworkIssue] = useState(false);
  const [partnerData, setPartnerData] = useState({});
  const [socialMediaLinks, setSocialMediaLinks] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const datePickerRef = useRef(null);
  /* Date Range Picker */
  function getLastDayOfMonth(year, month) {
    const nextMonth = month + 1;
    const date = new Date(year, nextMonth, 0);
    return date.getDate();
  }
  const month = new Date().getMonth();
  const year = new Date().getFullYear();

  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  const getStartDateForInitialState = () => {
    const actualDate = addDays(new Date(), +1 - new Date().getDate());
    const adjustedStartDate = setSeconds(
      setMinutes(setHours(actualDate, 0), 0),
      0
    );
    return adjustedStartDate;
  };

  const [state, setState] = useState([
    {
      startDate: getStartDateForInitialState(),
      endDate: new Date(year, month, getLastDayOfMonth(year, month)),
      key: "selection",
    },
  ]);

  //update dateFrom and dateTo based on state changes
  useEffect(() => {
    const formattedStartDate = formatDate(state[0].startDate);
    const formattedEndDate = formatDate(state[0].endDate);

    setdateFrom(formattedStartDate);
    setdateTo(formattedEndDate);
  }, [state]);
  /* Date Range Picker end*/

  // Date Picker outside click handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setShowDatePicker(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  /* new Date Picker Data */
  const handleSelect = (ranges) => {
    const { startDate, endDate } = ranges.selection;
    const adjustedEndDate = setSeconds(
      setMinutes(setHours(endDate, 23), 59),
      59
    );
    const adjustedStartDate = setSeconds(
      setMinutes(setHours(startDate, 0), 0),
      0
    );
    setState([
      {
        startDate: adjustedStartDate,
        endDate: adjustedEndDate,
        key: "selection",
      },
    ]);
  };

  const [dateFrom, setdateFrom] = useState(formatDate(state[0].startDate));
  const [dateTo, setdateTo] = useState(formatDate(state[0].endDate));

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${app_url}/api/partner/get_partner_by_id?id=${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const data = res?.data?.partner;
        setPartnerData(data);
        const links = data?.social_media_links;
        if (links) {
          try {
            setSocialMediaLinks(JSON.parse(links));
          } catch (error) {
            console.error("Error parsing social media links:", error);
            toast.error("Failed to parse social media links.");
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          if (err.response.status === 400)
            toast.error(err.response.data.message || "Bad Request");
          else if (err.response.status === 401) navigate("/");
          else if (err.response.status === 500)
            toast.error("Internal Server Error");
        } else {
          setNetworkIssue(true);
          toast.error("Network error. Please check your internet connection.");
          setFailed("Network error. Please check your internet connection.");
        }
      });
  }, []);

  if (networkIssue) {
    return <Error500 message={failed} />;
  }
  console.log(partnerData, "data check");

  // if (loading) {
  //   return <div className="text-center my-3">Loading...</div>;
  // }

  return (
    <section className="wrapper overflow-hidden">
      <div className="d-flex justify-content-between align-items-center w-100">
        <button className="btn btn-light" onClick={() => window.history.back()}>
          {/* <FaArrowLeft /> Back */}
          <i
            className="bi bi-arrow-left-circle-fill fs-3 me-3 my-auto pointer"
            onClick={() => window.history.back()}
          ></i>
        </button>
        <p className="heading">eTijwaal Partner Center</p>
      </div>

      <div className="d-xl-flex px-3 py-3 justify-content-xl-between table-header-item mb-2">
        {/* new Date Picker Data */}
        <div className="dropdown d-flex flex-wrap gap-3" ref={datePickerRef}>
          <button
            className="btn btn-white border dropdown-toggle"
            type="button"
            // data-bs-toggle="dropdown"
            // aria-expanded="false"
            // id="dropdownMenuClickableInside"
            // data-bs-auto-close="outside"
            onClick={toggleDatePicker}
          >
            Select Date
          </button>
          <div className="d-flex align-items-center gap-1 dropdown">
            <div className="order-date">
              <span className="pe-2">From:</span>
              <button
                type="button"
                disabled
                className="text-black btn btn-white border "
              >
                {dateFrom}
              </button>
            </div>
            <div className="order-date">
              <span className="pe-2">To:</span>
              <button
                type="button"
                disabled
                className="text-black btn btn-white border "
              >
                {dateTo}
              </button>
            </div>
          </div>
          {showDatePicker && (
            <ul
              // aria-labelledby="dropdownMenuClickableInside"
              className="dropdown-menu px-2 py-0 show"
              style={{ top: "46px", left: "-20px" }}
            >
              <li className="my-2 dropdown-item">
                <DateRangePicker
                  onChange={handleSelect}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={state}
                  direction="horizontal"
                />
              </li>
            </ul>
          )}
        </div>
      </div>

      <div className="row my-3">
        {/* Partner Details */}
        <div className="col-md-6">
          <div className="card shadow-sm">
            <div
              className="card-header text-white"
              style={{ background: "#F8A363" }}
            >
              <h5 className="mb-0">{partnerData?.agent_name}</h5>
            </div>
            <div className="card-body">
              <ul className="list-group">
                {/* <div className="card-body"> */}
                <li className="list-group-item">
                  <strong>Username:</strong> {partnerData?.username}
                </li>
                <li className="list-group-item">
                  <strong>Email:</strong> {partnerData?.email}
                </li>
                <li className="list-group-item">
                  <strong>Mobile:</strong> {partnerData?.mobile}
                </li>
                <li className="list-group-item">
                  <strong>Company Name:</strong> {partnerData?.company_name}
                </li>
                <li className="list-group-item">
                  <strong>Address:</strong> {partnerData?.address}
                </li>
                <li className="list-group-item">
                  <strong>Balance:</strong> {partnerData?.balance}{" "}
                  {partnerData?.agent_currency}
                </li>
                <li className="list-group-item">
                  <strong>Discount Percent:</strong>{" "}
                  {partnerData?.discount_percent}%
                </li>
                <li className="list-group-item">
                  <strong>Account Mode:</strong> {partnerData?.account_mode}
                </li>
                <li className="list-group-item">
                  <strong>Status:</strong>{" "}
                  {partnerData?.agent_status ? "Active" : "Inactive"}
                </li>
                <li className="list-group-item">
                  <strong>Joined:</strong>{" "}
                  {new Date(partnerData?.created_at).toLocaleDateString()}
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Social Media Links */}
        <div className="col-md-6">
          <div className="card shadow-sm" style={{ height: "30.5em" }}>
            <div
              className="card-header text-white"
              style={{ background: "#F8A363" }}
            >
              <h5 className="mb-0">Social Media Links</h5>
            </div>
            <div
              className="card-body"
              style={{ maxHeight: "29.5em", overflowY: "scroll" }}
            >
              {socialMediaLinks?.length > 0 ? (
                <>
                  <ul className="list-group">
                    {socialMediaLinks.map((link, index) => (
                      <li
                        key={index}
                        className="list-group-item"
                        // style={{ padding: "13.45px 5px" }}
                      >
                        <strong>{link.name}:</strong>{" "}
                        {link.url ? (
                          <a
                            href={link.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {link.url}
                          </a>
                        ) : (
                          "Not Provided"
                        )}
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <p>No social media links available.</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row ">
        <div className="col-lg-3 col-sm-6 my-3">
          <div className="card w-100">
            <div className="card-body pointer d-flex justify-content-between align-items-center">
              <div>
                <p className="fs-5">Total eSims Sold </p>
                <p className="heading m-0 text-black">10</p>
              </div>
              <div>
                <div className="back-secondary rounded-3 px-2 py-1">
                  <MdOutlineSimCardDownload size={30} fill="#fff" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-sm-6 my-3">
          <div className="card w-100">
            <div className="card-body pointer d-flex justify-content-between align-items-center">
              <div>
                <p className="fs-5">Total Top-ups Sold</p>
                <p className="heading m-0 text-black">10</p>
              </div>
              <div>
                <div className="back-secondary rounded-3 px-2 py-1">
                  <MdOutlineSimCardAlert size={30} fill="#fff" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-sm-6 my-3">
          <div className="card w-100">
            <div className="card-body pointer d-flex justify-content-between align-items-center">
              <div>
                <p className="fs-5">Total Sales Revenue</p>
                <p className="heading m-0 text-black">10</p>
              </div>
              <div>
                <div className="back-secondary rounded-3 px-2 py-1">
                  <FaDollarSign size={30} fill="#fff" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-sm-6 my-3">
          <div className="card w-100">
            <div className="card-body pointer d-flex justify-content-between align-items-center">
              <div>
                <p className="fs-5">Total eSim Orders</p>
                <p className="heading m-0 text-black">10</p>
              </div>
              <div>
                <div className="back-secondary rounded-3 px-2 py-1">
                  <FaShoppingCart size={30} fill="#fff" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* GEO-CHART */}
      <div className="row my-3">
        <div className="col-md-8">
          <div className="bg-white">
            <div className="d-flex mx-4 pt-3 border-bottom pb-3 mb-4 justify-content-between align-items-center">
              <h1 className=" heading">Trending eSIMs Data by Country</h1>
            </div>
            <GeoChart
            // geoChartData={trendingEsim} chartLoading={Loading}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="card border-0 h-100">
            <div className="card-body">
              <h2 className="pb-4 heading border-bottom mb-4">
                Country Metrics:
              </h2>
              {/* {Loading ? (
                <p className="fs-5">Loading...</p>
              ) : countryMetrics?.length === 0 ? (
                <p className="fs-5">No data found</p>
              ) : ( */}
              <div
                className="d-flex flex-column gap-3"
                style={{
                  maxHeight: "41em",
                  overflow: "scroll",
                }}
              >
                {/* {countryMetrics?.map((item, index) => {
                  const firstPackageCount =
                    countryMetrics[0]?.package_count || 0;
                  return (
                    <> */}
                <div
                  className="align-items-center justify-content-between gap-2"
                  // key={index}
                >
                  <div
                    style={{ width: "100%", marginBottom: "5px" }}
                    className="fs-4 d-flex align-items-center"
                  >
                    {/* {item?.flagImage_1 && ( */}
                    <img
                      // src={app_url + item?.flagImage_1}
                      alt=""
                      width={30}
                      className="me-2"
                    />
                    {/* )} */}
                    <div className="d-flex justify-content-between w-100">
                      <span className="fs-5">
                        {/* {item?.category.replace(/-/g, " ")} */}
                      </span>
                      {/* <span className="fs-5 m-1">{item?.package_count}</span> */}
                    </div>
                  </div>
                  <div
                    className="progress"
                    style={{
                      width: "100%",
                    }}
                  >
                    <div
                      className="progress-bar progress-bar-striped progress-bar-animated"
                      role="progressbar"
                      aria-label="country metrices"
                      // aria-valuenow={item?.package_count}
                      aria-valuemin="0"
                      // aria-valuemax={firstPackageCount}
                      // style={{
                      //   width: `${
                      //     (item.package_count / firstPackageCount) * 100
                      //   }%`,
                      // }}
                    ></div>
                    {/* {console.log(firstPackageCount)} */}
                  </div>
                </div>
                {/* </>
                  );
                })} */}
              </div>
              {/* )} */}
            </div>
          </div>
        </div>
        <div className="row my-3 mx-0">
          <div className="col-12 mt-2">
            <div className="card border-0 h-100">
              <div className="card-body">
                <p className="heading">Orders Processed (eSIMs/Topups)</p>
                <hr />
                <ChartOne
                  endPoint="getAdminDashboardOrders"
                  totalName="Orders"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mx-0">
        <div className="col-md-8 w-100 mt-2">
          <div className="card border-0 h-100">
            <div className="card-body">
              <p className="heading">Summarize Sales</p>
              <hr />
              <Leadsales />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PartnerDetails;
