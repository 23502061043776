import React, { useState } from "react";
import youtubeIcon from "../../../src/assets/images/youtube.png";
// import twitterIcon from "../../../src/assets/images/twitter.png";
import facebookIcon from "../../../src/assets/images/facebook.png";
import instagramIcon from "../../../src/assets/images/instagram.png";
import webIcon from "../../../src/assets/images/world-wide-web.png";
import tiktokIcon from "../../../src/assets/images/tiktok.png";
import toast from "react-hot-toast";
import { app_url } from "../../config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SocialMediaLinksModal from "../../components/modals/SocialMediaLinksModal";

const AddAffiliatePartner = () => {
  const [fullName, setFullName] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [nationality, setNationality] = useState("");
  const [countryOfResidence, setCountryOfResidence] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankAccountTitle, setBankAccountTitle] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [socialMediaLinks, setSocialMediaLinks] = useState([
    { slug: "youtube", name: "Youtube", url: null },
    { slug: "facebook", name: "Facebook", url: null },
    { slug: "instagram", name: "Instagram", url: null },
    { slug: "tiktok", name: "Tiktok", url: null },
    { slug: "website", name: "Website", url: null },
  ]);
  const [showModal, setShowModal] = useState(false);
  // Deep copy of socialMediaLinks for tempLinks to avoid direct mutation
  const [tempLinks, setTempLinks] = useState(
    JSON.parse(JSON.stringify(socialMediaLinks))
  );
  const [commissionRate, setCommissionRate] = useState("");
  const [commissionError, setCommissionError] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState("");
  const [discountError, setDiscountError] = useState("");
  const token = JSON.parse(localStorage.getItem("adminEtijwaal"))?.token;
  const navigate = useNavigate();

  const handleModalClose = () => {
    setShowModal(false);
    setTempLinks(JSON.parse(JSON.stringify(socialMediaLinks)));
    console.log(tempLinks, "temp links check");
  };

  const handleModalShow = () => setShowModal(true);

  const handleUrlChange = (index, event) => {
    const { value } = event.target;
    const updatedLinks = JSON.parse(JSON.stringify(tempLinks));

    // If input is empty, set url to null
    updatedLinks[index].url = value === "" ? null : value;

    // URL Validation: Check if URL starts with http:// or https://
    const urlPattern = /^(https?:\/\/)/;

    if (value && !urlPattern.test(value)) {
      updatedLinks[index].error = "URL must start with http:// or https://";
    } else {
      updatedLinks[index].error = "";
    }
    setTempLinks(updatedLinks);
  };

  // Save changes from modal to main state
  const handleSaveLinks = () => {
    setSocialMediaLinks(tempLinks);
    setShowModal(false);
  };

  const isSaveButtonEnabled = tempLinks.every((link) => {
    return link.url === "" || link.url === null || link.error === "";
  });

  const handleFullNameChange = (event) => {
    const value = event.target.value;

    // Check if the value matches the first name format
    const isValidFullNameFormat =
      /^[A-Za-z]+(?: [A-Za-z]+)*(?:[-'][A-Za-z]+)?$/.test(value);

    // Reset error messages
    setFullNameError("");

    if (!isValidFullNameFormat) {
      setFullNameError("Please enter a valid full name.");
      return;
    }

    setFullName(value);
  };

  const handleEmailChange = (event) => {
    const value = event.target.value;

    // Check if the value matches the email format
    const isValidEmailFormat =
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/.test(value);

    // Reset error messages
    setEmailError("");

    if (!isValidEmailFormat) {
      setEmailError("Please enter a valid email address.");
      return;
    }

    setEmail(value);
  };

  const handleCommissionChange = (event) => {
    const value = event.target.value;

    // Convert the value to a number for range validation
    const numValue = parseFloat(value);

    // Check if the value matches the pattern of a valid number within the range with up to three decimal places
    const isValidFormat =
      /^(0(\.\d{1,3})?|[1-9][0-9]?(\.\d{1,3})?|99(\.999)?)$/.test(value);

    // Reset error messages
    setCommissionError("");
    if (!isValidFormat) {
      // setCommissionRate(value);
      setCommissionError(
        "Please enter a valid number with exactly 3 decimal places (e.g., 12.340) and between 0 to 99.999."
      );
      return;
    }

    setCommissionRate(value);
  };

  const handleDiscountChange = (event) => {
    const value = event.target.value;

    // Convert the value to a number for range validation
    const numValue = parseFloat(value);

    // Check if the input is empty or has exactly two decimal places
    const isValidFormat =
      /^(100(\.0{1,2})?|([1-9]|[1-8][0-9]|9[0-4]|95)(\.\d{1,2})?|0)$/.test(
        value
      );
    console.log(isValidFormat, "check validation");

    // Reset error messages
    setDiscountError("");
    if (!isValidFormat) {
      setDiscountError(
        "Please enter a valid number with exactly 2 decimal places (e.g., 12.34 and between 1 to 95 or exactly 0 or 100)."
      );
      return;
    }

    setDiscountPercentage(value);
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission

    if (commissionError || discountError) {
      toast.error("Please fix the errors before submitting the form.");
      return;
    }
    const data = {
      full_name: fullName,
      email: email,
      phone_number: phoneNumber,
      nationality: nationality,
      country_of_residence: countryOfResidence,
      social_media_links: socialMediaLinks,
      commission_rate: commissionRate,
      discount_percentage: discountPercentage,
      bank_name: bankName,
      bank_account_title: bankAccountTitle,
      bank_account_number: bankAccountNumber,
    };
    axios
      .post(`${app_url}/api/affiliatePartner/registerAffiliatePartner`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            toast.error(error.response.data.message);
          } else if (error.response.status === 401) {
            toast.error(error.response.data.message);
            navigate("/");
          } else if (error.response.status === 500) {
            toast.error(error.response.data.message);
          } else {
            toast.error(error.response.data.message);
          }
        } else {
          toast.error(
            error?.response?.data?.message ||
              "Network error. Please check your internet connection."
          );
        }
        console.error("Error:", error.response);
      });
  };

  return (
    <section className="wrapper">
      <div className="d-flex flex-wrap gap-3 justify-content-between">
        <div className="d-flex justify-content-between align-items-center w-100">
          <p className="heading mb-0">
            <i
              className="bi bi-arrow-left-circle-fill fs-3 me-3 my-auto pointer"
              onClick={() => window.history.back()}
            ></i>
            Add Affiliate Partner
          </p>
        </div>
      </div>
      <form className="" onSubmit={handleSubmit}>
        <div className="mt-4">
          <p className="fw-bold fs-4 mb-0">Personal Information</p>
          <div className="row mt-sm-3 mt-0">
            <div className="col-sm-6 col-12 mt-sm-0 mt-3">
              <label htmlFor="fullName" className="form-label">
                Full Name*
              </label>
              <input
                type="text"
                className="form-control"
                id="fullName"
                onChange={handleFullNameChange}
                placeholder="Enter full name"
                required
                maxLength={30}
              />
              {fullNameError && (
                <div className="text-danger mt-1">{fullNameError}</div>
              )}
            </div>
            <div className="col-sm-6 col-12 mt-sm-0 mt-3">
              <label htmlFor="email" className="form-label">
                Email*
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                onChange={handleEmailChange}
                placeholder="Enter email"
                required
                maxLength={100}
              />
              {emailError && (
                <div className="text-danger mt-1">{emailError}</div>
              )}
            </div>
          </div>
          <div className="row mt-sm-3 mt-0">
            <div className="col-sm-6 col-12 mt-sm-0 mt-3">
              <label htmlFor="phoneNumber" className="form-label">
                Phone Number
              </label>
              <input
                type="text"
                className="form-control"
                id="phoneNumber"
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder="Enter phone number"
                maxLength={30}
              />
            </div>

            <div className="col-sm-6 col-12 mt-sm-0 mt-3">
              <label htmlFor="nationality" className="form-label">
                Nationality
              </label>
              <input
                type="text"
                className="form-control"
                id="nationality"
                onChange={(e) => setNationality(e.target.value)}
                placeholder="Enter nationality"
                maxLength={30}
              />
            </div>
          </div>
          <div className="row mt-sm-3 mt-0">
            <div className="col-sm-6 col-12 mt-sm-0 mt-3">
              <label htmlFor="countryOfResidence" className="form-label">
                Country of Residence
              </label>
              <input
                type="text"
                className="form-control"
                id="countryOfResidence"
                onChange={(e) => setCountryOfResidence(e.target.value)}
                placeholder="Enter country of residence"
                maxLength={30}
              />
            </div>
          </div>
        </div>
        {/* Commision & Discounts */}
        <div className="mt-4">
          <p className="fw-bold fs-4 mb-0">Commision & Discounts</p>
          <div>
            <h5 className="text-danger">Note:</h5>
            <li>
              Comission rate must be between 0 to 99.999 and include 3 decimal
              places (e.g., 12.340)
            </li>
            <li>
              Discount percentage must be between 0 to 99.99 and include 2
              decimal places (e.g., 12.34)
            </li>
          </div>
          <div className="row mt-sm-3 mt-0">
            <div className="col-sm-6 col-12 mt-sm-0 mt-3">
              <label htmlFor="commissionRate" className="form-label">
                Commission Rate
              </label>
              <div style={{ position: "relative" }}>
                <input
                  type="text"
                  className="form-control"
                  id="commissionRate"
                  placeholder="Enter commission rate"
                  onChange={handleCommissionChange}
                  required
                  style={{ paddingRight: "22px" }}
                />
                <span
                  style={{ position: "absolute", right: "10px", bottom: "5px" }}
                >
                  %
                </span>
              </div>
              {commissionError && (
                <div className="text-danger mt-1">{commissionError}</div>
              )}
            </div>
            <div className="col-sm-6 col-12 mt-sm-0 mt-3">
              <label htmlFor="discountPercentage" className="form-label">
                Discount Percentage
              </label>
              <div style={{ position: "relative" }}>
                <input
                  type="text"
                  className="form-control"
                  id="discountPercentage"
                  placeholder="Enter discount percentage"
                  onChange={handleDiscountChange}
                  required
                  style={{ paddingRight: "22px" }}
                />
                <span
                  style={{ position: "absolute", right: "10px", bottom: "5px" }}
                >
                  %
                </span>
              </div>
              {discountError && (
                <div className="text-danger mt-1">{discountError}</div>
              )}
            </div>
          </div>
        </div>
        {/* Bank Information */}
        <div className="mt-4">
          <p className="fw-bold fs-4 mb-0">Bank Information</p>

          <div className="row mt-sm-3 mt-0">
            <div className="col-sm-6 col-12 mt-sm-0 mt-3">
              <label htmlFor="bankName" className="form-label">
                Bank Name
              </label>
              <input
                type="text"
                className="form-control"
                id="bankName"
                onChange={(e) => setBankName(e.target.value)}
                placeholder="Enter bank name"
                maxLength={100}
              />
            </div>

            <div className="col-sm-6 col-12 mt-sm-0 mt-3">
              <label htmlFor="bankAccountTitle" className="form-label">
                Bank Account Title
              </label>
              <input
                type="text"
                className="form-control"
                id="bankAccountTitle"
                onChange={(e) => setBankAccountTitle(e.target.value)}
                placeholder="Enter bank account title"
                maxLength={100}
              />
            </div>
          </div>
          <div className="row mt-sm-3 mt-0">
            <div className="col-sm-6 col-12 mt-sm-0 mt-3">
              <label htmlFor="bankAccountNumber" className="form-label">
                Bank Account Number
              </label>
              <input
                type="text"
                className="form-control"
                id="bankAccountNumber"
                onChange={(e) => setBankAccountNumber(e.target.value)}
                placeholder="Enter bank account number"
                maxLength={30}
              />
            </div>
          </div>
        </div>
        {/* Social Media Links */}
        <div className="mt-4">
          <p className="fw-bold fs-4 mb-0">Social Media Links</p>
          <div className="row">
            {socialMediaLinks.map((link, index) => (
              <div className="col-sm-6 col-12" key={link.slug}>
                <label
                  htmlFor={link.slug}
                  className="form-label d-flex gap-1 mt-3"
                >
                  <img
                    src={
                      link.slug === "youtube"
                        ? youtubeIcon
                        : link.slug === "facebook"
                        ? facebookIcon
                        : link.slug === "instagram"
                        ? instagramIcon
                        : link.slug === "tiktok"
                        ? tiktokIcon
                        : webIcon
                    }
                    alt={`${link.name} icon`}
                    width={20}
                    height={20}
                  />
                  {link.name}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id={link.slug}
                  placeholder={`${link.name} link`}
                  disabled
                  value={link.url}
                  maxLength={1500}
                />
              </div>
            ))}
            <div className="col-sm-6 col-12" style={{ marginTop: "25px" }}>
              <button
                type="button"
                className="btn card-btn mt-3 w-100 d-flex align-items-center justify-content-center"
                onClick={handleModalShow}
              >
                <i className="bi bi-plus text-white fs-5"></i> Add Social Media
                Links
              </button>
            </div>
          </div>
        </div>
        <div className="w-100 d-flex justify-content-center">
          <button type=" submit" className="btn primary-btn mt-4 px-4">
            Submit
          </button>
        </div>
      </form>

      {/* Modal for Social Media Links */}
      {showModal && (
        <SocialMediaLinksModal
          handleModalClose={handleModalClose}
          handleUrlChange={handleUrlChange}
          tempLinks={tempLinks}
          handleSaveLinks={handleSaveLinks}
          isSaveButtonEnabled={isSaveButtonEnabled}
        />
      )}
    </section>
  );
};

export default AddAffiliatePartner;
