import React, { useEffect, useState } from "react";
import Loader from "../Loader";
import { app_url } from "../../config";

const Notifications = ({ data, isLoading }) => {
  const [notificationData, setNotificationData] = useState(null);

  useEffect(() => {
    let settings = data.map(({ created_at, ...rest }) => {
      let utcFormat = new Date().toISOString();
      const datePosted = new Date(created_at);
      const currentDate = new Date(utcFormat);

      const timeDiff = currentDate - datePosted;
      const timeElapsedInSeconds = Math.floor(timeDiff / 1000);
      const timeElapsedInMinutes = Math.floor(timeDiff / (1000 * 60));
      const timeElapsedInHours = Math.floor(timeDiff / (1000 * 60 * 60));
      const timeElapsedInDays = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      const timeElapsedInMonths = Math.floor(
        timeDiff / (1000 * 60 * 60 * 30.44)
      );
      const timeElapsedInYears = Math.floor(
        timeDiff / (1000 * 60 * 60 * 24 * 365)
      );

      if (timeElapsedInSeconds < 60) {
        return { created_at: `few seconds ago`, ...rest };
      } else if (timeElapsedInSeconds > 60 && timeElapsedInSeconds <= 3600) {
        return {
          created_at: `${
            timeElapsedInMinutes === 1
              ? `${timeElapsedInMinutes} minute ago`
              : `${timeElapsedInMinutes} minutes ago`
          }`,
          ...rest,
        };
      } else if (timeElapsedInMinutes > 60 && timeElapsedInMinutes < 1400) {
        return {
          created_at: `${
            timeElapsedInHours === 1
              ? `${timeElapsedInHours} hour ago`
              : `${timeElapsedInHours} hours ago`
          }`,
          ...rest,
        };
      } else if (timeElapsedInHours >= 24) {
        return {
          created_at: `${
            timeElapsedInDays === 1
              ? `${timeElapsedInDays} day ago`
              : `${timeElapsedInDays} days ago`
          }`,
          ...rest,
        };
      } else if (timeElapsedInMonths) {
        return {
          created_at: `${
            timeElapsedInMonths === 1
              ? `${timeElapsedInMonths} month ago`
              : `${timeElapsedInMonths} months`
          } ago`,
          ...rest,
        };
      } else if (timeElapsedInYears) {
        return {
          created_at: `${
            timeElapsedInYears === 1
              ? `${timeElapsedInYears} year ago`
              : `${timeElapsedInYears} years ago`
          }`,
          ...rest,
        };
      }
    });
    setNotificationData(settings);
  }, [data]);

  return (
    <>
      <article className="mb-8">
        {isLoading ? (
          <div
            className="h-[400px] w-100 d-flex align-items-center justify-content-center"
            style={{ height: "400px" }}
          >
            <Loader />
          </div>
        ) : data.length === 0 ? (
          <div
            className="w-full d-flex align-items-center justify-content-center"
            style={{ height: "400px" }}
          >
            <p className="fs-2">You have no Notifications!</p>
          </div>
        ) : (
          notificationData?.map((results, index) => (
            <>
              <div
                key={index}
                style={{
                  backgroundColor:
                    results?.status === "read" ? "#fef2f2" : "white",
                }}
                className="transition-colors px-1 px-sm-4 py-2 border-bottom"
              >
                <div className="position-relative px-1 sm:px-4 py-2 border-b">
                  <div className="text-xs sm:text-sm flex items-center gap-2 d-flex">
                    <div className="w-[48px] h-[48px] rounded-full flex items-center justify-center">
                      <img src={app_url + results?.thumbnail} alt="thumbnail" />
                    </div>
                    <div>
                      <p className="fs-5 mb-0 fw-semibold text-base">
                        {results?.title}
                        Test
                      </p>
                      <p className="fs-thin fs-6 mb-0">{results?.message}</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <p className="fs-6 mb-0">{results?.created_at}</p>
                  </div>
                </div>
              </div>
            </>
          ))
        )}
      </article>
    </>
  );
};

export default Notifications;
