import React, { useEffect, useRef } from "react";
import { app_url } from "../../config";
import axios from "axios";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { DateRangePicker } from "react-date-range";
import { addDays, setSeconds, setHours, setMinutes } from "date-fns";
import { formatDate } from "../../formatDate/formatDate";

const TopPerformingPartnersTable = () => {
  const [filteredBy, setFilteredBy] = useState("revenue");
  const [isLoading, setIsLoading] = useState("");
  const [partnersData, setPartnersData] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const navigate = useNavigate();
  const datePickerRef = useRef(null);
  const token = JSON.parse(localStorage.getItem("adminEtijwaal"))?.token;

  /* Date Range Picker */
  function getLastDayOfMonth(year, month) {
    const nextMonth = month + 1;
    const date = new Date(year, nextMonth, 0);
    return date.getDate();
  }
  const month = new Date().getMonth();
  const year = new Date().getFullYear();

  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  const getStartDateForInitialState = () => {
    const actualDate = addDays(new Date(), +1 - new Date().getDate());
    const adjustedStartDate = setSeconds(
      setMinutes(setHours(actualDate, 0), 0),
      0
    );
    return adjustedStartDate;
  };

  const [state, setState] = useState([
    {
      startDate: getStartDateForInitialState(),
      endDate: new Date(year, month, getLastDayOfMonth(year, month)),
      key: "selection",
    },
  ]);

  //update dateFrom and dateTo based on state changes
  useEffect(() => {
    const formattedStartDate = formatDate(state[0].startDate);
    const formattedEndDate = formatDate(state[0].endDate);

    setdateFrom(formattedStartDate);
    setdateTo(formattedEndDate);
  }, [state]);
  /* Date Range Picker end*/

  // Date Picker outside click handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setShowDatePicker(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  /* new Date Picker Data */
  const handleSelect = (ranges) => {
    const { startDate, endDate } = ranges.selection;
    const adjustedEndDate = setSeconds(
      setMinutes(setHours(endDate, 23), 59),
      59
    );
    const adjustedStartDate = setSeconds(
      setMinutes(setHours(startDate, 0), 0),
      0
    );
    setState([
      {
        startDate: adjustedStartDate,
        endDate: adjustedEndDate,
        key: "selection",
      },
    ]);
  };

  const [dateFrom, setdateFrom] = useState(formatDate(state[0].startDate));
  const [dateTo, setdateTo] = useState(formatDate(state[0].endDate));

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `${app_url}/api/dashboard/getTopPeformingPartners_forAdminP?from=${dateFrom}&to=${dateTo}&slug=${"revenue"}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setPartnersData(res?.data?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response) {
          const status = err.response.status;
          if (status === 400) {
            toast.error(err.response.data.message || "Bad Request");
          } else if (status === 401) {
            navigate("/");
          } else if (status === 500) {
            toast.error(err.response.data.message || "Internal Server Error");
          } else {
            toast.error("An unexpected error occurred.");
          }
        } else {
          toast.error("Network error. Please check your internet connection.");
        }
      });
  }, [dateFrom, dateTo, filteredBy]);

  console.log(partnersData, "data check");

  const newArr = new Array(partnersData.length).fill(
    <p className="placeholder-glow">
      <span className="placeholder col-12"></span>
    </p>
  );

  const skeleton = (
    <tbody>
      {newArr.map((item, index) => (
        <tr key={index}>
          <th scope="row" className="py-2 ps-5">
            {item}
          </th>
          <th scope="row" className="py-2">
            {item}
          </th>
          <th scope="row" style={{ textTransform: "none" }} className="py-2">
            {item}
          </th>
          <th scope="row" className="text-center py-2">
            {item}
          </th>
          <th scope="row" className="text-center py-2">
            {item}
          </th>
          <th scope="row" className="text-center py-2">
            {item}
          </th>
        </tr>
      ))}
    </tbody>
  );

  return (
    <>
      {/* Date Filter */}
      <div className="d-md-flex pb-3 table-header-item mb-2">
        {/* new Date Picker Data */}
        <div
          className="dropdown d-flex flex-wrap m-md-0 gap-sm-3 gap-2"
          ref={datePickerRef}
        >
          <button
            className="btn btn-white border dropdown-toggle"
            type="button"
            onClick={toggleDatePicker}
          >
            Select Date
          </button>
          <div className="d-flex align-items-center gap-1 dropdown">
            <div className="order-date">
              <span className="pe-2">From:</span>
              <button
                type="button"
                disabled
                className="text-black btn btn-white border "
              >
                {dateFrom}
              </button>
            </div>
            <div className="order-date">
              <span className="pe-2">To:</span>
              <button
                type="button"
                disabled
                className="text-black btn btn-white border "
              >
                {dateTo}
              </button>
            </div>
          </div>
          {showDatePicker && (
            <ul
              // aria-labelledby="dropdownMenuClickableInside"
              className="dropdown-menu px-2 py-0 show"
              style={{ top: "46px", left: "-20px" }}
            >
              <li className="my-2 dropdown-item">
                <DateRangePicker
                  onChange={handleSelect}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={state}
                  direction="horizontal"
                />
              </li>
            </ul>
          )}
        </div>

        <div className="d-flex align-items-center gap-2 mt-md-0 mt-2">
          <p className="fs-5 mb-0 fw-semibold">Show:</p>
          <select
            value={filteredBy}
            onChange={(e) => {
              setFilteredBy(e.target.value);
            }}
            className="border rounded px-2 py-1 "
            style={{ width: 200 }}
          >
            <option value="" disabled>
              Filter
            </option>
            <option value="revenue">Revenue</option>
            <option value="bundles_sold">Bundles Sold</option>
            <option value="esims_sold">eSIMS Sold</option>
            <option value="topups_sold">Topups Sold</option>
          </select>
        </div>
      </div>

      <div
        className="table-responsive"
        style={{
          maxHeight: "41em",
          overflow: "scroll",
        }}
      >
        <table className="table table-tb">
          <thead className="">
            <tr className="rounded">
              <th className="tr-tt fw-bold ps-5" scope="col">
                #
              </th>
              <th className="tr-tt fw-bold" scope="col">
                Agent Name
              </th>
              <th className="tr-tt fw-bold" scope="col">
                Total Price
              </th>
              <th className="tr-tt fw-bold" scope="col">
                Total Packages
              </th>
              <th className="tr-tt fw-bold" scope="col">
                eSIMS Sold
              </th>
              <th className="tr-tt fw-bold" scope="col">
                eSIMS Topup Sold
              </th>
            </tr>
          </thead>

          {isLoading ? (
            skeleton
          ) : (
            <tbody>
              {partnersData.length == 0 ? (
                <tr>
                  <th className="py-2"></th>
                  <th className="py-2"></th>
                  <th className="py-2 align-middle">No Data Found</th>
                  <th className="py-2"></th>
                </tr>
              ) : (
                partnersData?.map(
                  (
                    {
                      agent_name,
                      esim_sold,
                      total_price,
                      total_packages,
                      esim_topup_sold,
                    },
                    index
                  ) => {
                    return (
                      <tr key={index}>
                        <th className="py-2 ps-5 align-middle">{index + 1}</th>
                        <th className="py-2 align-middle">{agent_name}</th>
                        <th className="py-2 align-middle">
                          ${total_price.toFixed(2)}
                        </th>
                        <th className="py-2 align-middle">{total_packages}</th>
                        <th className="py-2 align-middle">{esim_sold}</th>
                        <th className="py-2 align-middle">{esim_topup_sold}</th>
                      </tr>
                    );
                  }
                )
              )}
            </tbody>
          )}
        </table>
      </div>
    </>
  );
};

export default TopPerformingPartnersTable;
