import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const TopBundlesTable = ({ tabledata, isLoading }) => {
  useEffect(() => {}, [tabledata]);

  const newArr = new Array(12).fill(
    <p className="placeholder-glow">
      <span className="placeholder col-12"></span>
    </p>
  );

  const skeleton = (
    <tbody>
      {newArr.map((item, index) => (
        <tr key={index}>
          <th scope="row" className="py-2 ps-5">
            {item}
          </th>
          <th scope="row" className="py-2">
            {item}
          </th>
          <th scope="row" style={{ textTransform: "none" }} className="py-2">
            {item}
          </th>
          <th scope="row" className="text-center py-2">
            {item}
          </th>
        </tr>
      ))}
    </tbody>
  );

  // Sorting topBundleSold array by total_price in descending order
  const sortedData = tabledata?.topBundleSold
    ? [...tabledata.topBundleSold].sort(
        (a, b) => b.total_packages - a.total_packages
      )
    : [];

  return (
    <div
      className="table-responsive"
      style={{
        maxHeight: "41em",
        overflow: "scroll",
      }}
    >
      <table className="table table-tb">
        <thead className="">
          <tr className="rounded">
            <th className="tr-tt fw-bold ps-5" scope="col">
              #
            </th>
            <th className="tr-tt fw-bold" scope="col">
              Name
            </th>
            <th className="tr-tt fw-bold" scope="col">
              Total Price
            </th>
            <th className="tr-tt fw-bold" scope="col">
              Total Packages
            </th>
          </tr>
        </thead>

        {isLoading ? (
          skeleton
        ) : (
          <tbody>
            {sortedData.length == 0 ? (
              <tr>
                <th className="py-2"></th>
                <th className="py-2"></th>
                <th className="py-2 align-middle">No Data Found</th>
                <th className="py-2"></th>
              </tr>
            ) : (
              tabledata?.topBundleSold?.map(
                ({ packageName, total_price, total_packages }, index) => {
                  return (
                    <tr key={index}>
                      <td
                        className="py-2 ps-5 align-middle"
                        style={
                          index === 0
                            ? { backgroundColor: "#FD9137" }
                            : index === 1
                            ? { backgroundColor: "#8D99AE" }
                            : index === 2
                            ? { backgroundColor: "#E5E5E5" }
                            : {}
                        }
                      >
                        {index + 1}
                      </td>
                      <td
                        className="py-2 align-middle"
                        style={
                          index === 0
                            ? { backgroundColor: "#FD9137" }
                            : index === 1
                            ? { backgroundColor: "#8D99AE" }
                            : index === 2
                            ? { backgroundColor: "#E5E5E5" }
                            : {}
                        }
                      >
                        {packageName}
                      </td>
                      <td
                        className="py-2 align-middle"
                        style={
                          index === 0
                            ? { backgroundColor: "#FD9137" }
                            : index === 1
                            ? { backgroundColor: "#8D99AE" }
                            : index === 2
                            ? { backgroundColor: "#E5E5E5" }
                            : {}
                        }
                      >
                        ${total_price.toFixed(2)}
                      </td>
                      <td
                        className="py-2 align-middle"
                        style={
                          index === 0
                            ? { backgroundColor: "#FD9137" }
                            : index === 1
                            ? { backgroundColor: "#8D99AE" }
                            : index === 2
                            ? { backgroundColor: "#E5E5E5" }
                            : {}
                        }
                      >
                        {total_packages}
                      </td>
                    </tr>
                  );
                }
              )
            )}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default TopBundlesTable;
