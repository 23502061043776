import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { app_url } from "../../config";
import Loader from "../../components/Loader";
import toast from "react-hot-toast";
import Error500 from "../../components/500/Error500";
import ProductUpdatesDetailModal from "../../components/modals/ProductUpdatesDetailModal";

const ProductUpdatesDetail = () => {
  const token = JSON.parse(localStorage.getItem("adminEtijwaal"))?.token;
  const [productUpdatesDetailData, setProductUpdatesDetailData] = useState({});
  const [loading, setLoading] = useState(false);
  const [failed, setFailed] = useState("");
  const [networkIssue, setNetworkIssue] = useState(false);
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [wrongId, setWrongId] = useState(false);
  const [result, setResult] = useState({
    need_to_add_new_lc_with_its_packages: [],
    need_to_active_existing_lc: [],
    need_to_inactive_existing_lc: [],
    need_to_add_new_packages_in_existing_lc: [],
    need_to_add_new_regions_with_its_packages: [],
    need_to_add_network_coverages_in_lc: [],
    need_to_remove_network_coverages_from_lc: [],
    need_to_active_existing_regions: [],
    need_to_inactive_existing_regions: [],
    need_to_add_new_packages_in_existing_region_global: [],
    need_to_update_existed_local_packages: [],
    need_to_update_existed_regional_and_global_packages: [],
    need_to_add_supported_countries_in_regions: [],
    need_to_remove_supported_countries_from_regions: [],
    need_to_add_supported_countries_in_global: [],
    need_to_remove_supported_countries_from_global: [],
    need_to_add_network_coverages_in_regions: [],
    need_to_remove_network_coverages_from_regions: [],
    need_to_add_network_coverages_in_global: [],
    need_to_remove_network_coverages_from_global: [],
  });
  // State for modal
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [modalColumns, setModalColumns] = useState([]);

  // Api call to fetch uuid data
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${app_url}/api/productUpdate/getProductUpdateById?uuid=${uuid}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setProductUpdatesDetailData(res?.data?.result);
        setResult({
          need_to_add_new_lc_with_its_packages:
            res?.data?.result?.need_to_add_new_lc_with_its_packages || [],
          need_to_active_existing_lc:
            res?.data?.result?.need_to_active_existing_lc || [],
          need_to_inactive_existing_lc:
            res?.data?.result?.need_to_inactive_existing_lc || [],
          need_to_add_new_packages_in_existing_lc:
            res?.data?.result?.need_to_add_new_packages_in_existing_lc || [],
          need_to_add_new_regions_with_its_packages:
            res?.data?.result?.need_to_add_new_regions_with_its_packages || [],
          need_to_add_network_coverages_in_lc:
            res?.data?.result?.need_to_add_network_coverages_in_lc || [],
          need_to_remove_network_coverages_from_lc:
            res?.data?.result?.need_to_remove_network_coverages_from_lc || [],
          need_to_inactive_existing_regions:
            res?.data?.result?.need_to_inactive_existing_regions || [],
          need_to_add_new_packages_in_existing_region_global:
            res?.data?.result
              ?.need_to_add_new_packages_in_existing_region_global || [],
          need_to_update_existed_local_packages:
            res?.data?.result?.need_to_update_existed_local_packages || [],
          need_to_update_existed_regional_and_global_packages:
            res?.data?.result
              ?.need_to_update_existed_regional_and_global_packages || [],
          need_to_remove_supported_countries_from_regions:
            res?.data?.result
              ?.need_to_remove_supported_countries_from_regions || [],
          need_to_active_existing_regions:
            res?.data?.result?.need_to_active_existing_regions || [],
          need_to_add_supported_countries_in_regions:
            res?.data?.result?.need_to_add_supported_countries_in_regions || [],
          need_to_add_supported_countries_in_global:
            res?.data?.result?.need_to_add_supported_countries_in_global || [],
          need_to_remove_supported_countries_from_global:
            res?.data?.result?.need_to_remove_supported_countries_from_global ||
            [],
          need_to_add_network_coverages_in_regions:
            res?.data?.result?.need_to_add_network_coverages_in_regions || [],
          need_to_remove_network_coverages_from_regions:
            res?.data?.result?.need_to_remove_network_coverages_from_regions ||
            [],
          need_to_add_network_coverages_in_global:
            res?.data?.result?.need_to_add_network_coverages_in_global || [],
          need_to_remove_network_coverages_from_global:
            res?.data?.result?.need_to_remove_network_coverages_from_global ||
            [],
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          if (err.response.status === 400) {
            toast.error(err.response.data.message || "Bad Request");
          } else if (err.response.status === 401) {
            navigate("/");
          } else if (err.response.status === 500) {
            toast.error(err.response.data.message || "Internal Server Error");
          } else {
            toast.error("An unexpected error occurred.");
          }
        } else {
          setNetworkIssue(true);
          toast.error("Network error. Please check your internet connection.");
          setFailed("Network error. Please check your internet connection.");
        }
      });
  }, []);

  // Function to handle click on a section
  const handleSectionClick = (key) => {
    setShowModal(true);
    const data = result[key]?.map((item) => {
      if (item?.network_types) {
        return {
          ...item,
          network_types: JSON.parse(item.network_types).join(", "),
        };
      }
      return item;
    });
    // console.log("Modal open check");
    setModalData(data);
    let columns = [];
    if (key.includes("need_to_add_new_lc_with_its_packages")) {
      columns = ["country_name", "country_code"];
    } else if (key.includes("need_to_active_existing_lc")) {
      columns = ["country_name", "country_code", "current_status"];
    } else if (key.includes("need_to_inactive_existing_lc")) {
      columns = ["country_name", "country_code", "current_status"];
    } else if (key.includes("need_to_add_new_packages_in_existing_lc")) {
      columns = [
        "title",
        "country_code",
        "plan_type",
        "package",
        "day",
        "data_mb",
        "voice",
        "text",
        "sale_price",
        "net_price",
      ];
    } else if (key.includes("need_to_add_new_regions_with_its_packages")) {
      columns = ["region_name"];
    } else if (key.includes("need_to_add_network_coverages_in_lc")) {
      columns = [
        "plan_type",
        "country_code",
        "network_name",
        "network_types",
        "type",
      ];
    } else if (key.includes("need_to_remove_network_coverages_from_lc")) {
      columns = [
        "plan_type",
        "country_code",
        "network_name",
        "network_types",
        "type",
      ];
    } else if (key.includes("need_to_active_existing_regions")) {
      columns = ["region_name", "current_status"];
    } else if (key.includes("need_to_inactive_existing_regions")) {
      columns = ["region_name", "current_status"];
    } else if (
      key.includes("need_to_add_new_packages_in_existing_region_global")
    ) {
      columns = [
        "title",
        "slug",
        "plan_type",
        "package",
        "day",
        "data_mb",
        "voice",
        "text",
        "sale_price",
        "net_price",
      ];
    } else if (key.includes("need_to_update_existed_local_packages")) {
      columns = [
        "third_party_slug",
        "our_system_package_name",
        "carrier_package_id",
        "old_price",
        "new_price",
        "old_netPrice",
        "new_netPrice",
        "old_rechargeability",
        "new_rechargeability",
        "old_kyc",
        "new_kyc",
        "our_type",
        "updates",
      ];
    } else if (
      key.includes("need_to_update_existed_regional_and_global_packages")
    ) {
      columns = [
        "third_party_slug",
        "our_system_package_name",
        "carrier_package_id",
        "old_price",
        "new_price",
        "old_netPrice",
        "new_netPrice",
        "old_rechargeability",
        "new_rechargeability",
        "old_kyc",
        "new_kyc",
        "our_type",
        "updates",
      ];
    } else if (key.includes("need_to_add_supported_countries_in_regions")) {
      columns = ["regionSlug", "countryCode", "name"];
    } else if (
      key.includes("need_to_remove_supported_countries_from_regions")
    ) {
      columns = ["regionSlug", "countryCode", "name"];
    } else if (key.includes("need_to_add_supported_countries_in_global")) {
      columns = ["countryCode", "name"];
    } else if (key.includes("need_to_remove_supported_countries_from_global")) {
      columns = ["name", "countryCode"];
    } else if (key.includes("need_to_add_network_coverages_in_regions")) {
      columns = [
        "regionSlug",
        "plan_type",
        "country_code",
        "network_name",
        "network_types",
        "type",
      ];
    } else if (key.includes("need_to_remove_network_coverages_from_regions")) {
      columns = [
        "regionSlug",
        "plan_type",
        "country_code",
        "network_name",
        "network_types",
        "type",
      ];
    } else if (key.includes("need_to_add_network_coverages_in_global")) {
      columns = [
        "plan_type",
        "country_code",
        "network_name",
        "network_types",
        "type",
      ];
    } else if (key.includes("need_to_remove_network_coverages_from_global")) {
      columns = [
        "plan_type",
        "country_code",
        "network_name",
        "network_types",
        "type",
      ];
    }
    setModalColumns(columns);
  };

  // Function to render a section with a header and a list of items
  const renderSection = (header, items) => {
    const filteredItems = items.filter((item) => result[item.key].length > 0);
    if (!filteredItems.length) return null;
    return (
      <>
        <div className="product-update-details mt-3">
          <h2 className="section-header">{header}</h2>
          {filteredItems.map((item, index) => (
            <div
              className="product-update-details-row"
              key={index}
              onClick={() => handleSectionClick(item.key)}
            >
              <p className="product-update-details-row-label"> {item.label}</p>
              <div className="product-update-details-text">
                <p className="update">Updates: </p>
                <p className="product-update-details-row-length">
                  ({result[item.key].length})
                </p>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  // local and global/regional keys and labels
  const localItems = [
    {
      key: "need_to_add_new_lc_with_its_packages",
      label: "Need to Add New Local Countries",
    },
    {
      key: "need_to_active_existing_lc",
      label: "Need to Active Existing Local Countries",
    },
    {
      key: "need_to_inactive_existing_lc",
      label: "Need to Inactive Existing Local Countries",
    },
    {
      key: "need_to_add_new_packages_in_existing_lc",
      label: "Need to Add New Packages in Existing Local Countries",
    },
    {
      key: "need_to_add_network_coverages_in_lc",
      label: "Need to Add Network Coverages in Local Countries",
    },
    {
      key: "need_to_remove_network_coverages_from_lc",
      label: "Need to Remove Network Coverages from Local Countries",
    },
    {
      key: "need_to_update_existed_local_packages",
      label: "Need to Update Existed Local Packages",
    },
  ];

  const globalRegionalItems = [
    {
      key: "need_to_add_new_regions_with_its_packages",
      label: "Need to Add New Regions with Packages",
    },
    { key: "need_to_active_existing_regions", label: "Need to Active Regions" },
    {
      key: "need_to_inactive_existing_regions",
      label: "Need to Inactive Regions",
    },
    {
      key: "need_to_add_new_packages_in_existing_region_global",
      label: "Need to New Packages in Existing Regions/Global",
    },
    {
      key: "need_to_update_existed_regional_and_global_packages",
      label: "Need to Update Existed Regional and Global Packages",
    },
    {
      key: "need_to_add_supported_countries_in_regions",
      label: "Need to Add Supported Countries in Regions",
    },
    {
      key: "need_to_remove_supported_countries_from_regions",
      label: "Need to Remove Supported Countries from Regions",
    },
    {
      key: "need_to_add_supported_countries_in_global",
      label: "need to Add Supported Countries in Global",
    },
    {
      key: "need_to_remove_supported_countries_from_global",
      label: "Need to Remove Supported Countries from Global",
    },
    {
      key: "need_to_add_network_coverages_in_regions",
      label: "Need to Add Network Coverages in Regions",
    },
    {
      key: "need_to_remove_network_coverages_from_regions",
      label: "Need to Remove Network Coverages from Regions",
    },
    {
      key: "need_to_add_network_coverages_in_global",
      label: "Need to Add Network Coverages in Global",
    },
    {
      key: "need_to_remove_network_coverages_from_global",
      label: "Need to Remove Network Coverages from Global",
    },
  ];

  if (loading)
    return (
      <section className="wrapper">
        <div className="d-flex flex-wrap gap-3 justify-content-between">
          <div className="d-flex align-items-center gap-2">
            <i
              className="bi bi-arrow-left-circle-fill fs-3 my-auto pointer"
              onClick={() => window.history.back()}
            ></i>
            <p className="heading" style={{ marginBottom: 0 }}>
              Product Updates Detail
            </p>
          </div>
        </div>
        <div className="h-75">
          <Loader />
        </div>
      </section>
    );
  if (wrongId)
    return (
      <section className="wrapper">
        <div className="d-flex flex-wrap gap-3 justify-content-between">
          <div className="d-flex align-items-center gap-2">
            <i
              className="bi bi-arrow-left-circle-fill fs-3 my-auto pointer"
              onClick={() => window.history.back()}
            ></i>
            <p className="heading" style={{ marginBottom: 0 }}>
              Product Updates Detail
            </p>
          </div>
        </div>
        <div className="product-update-details mt-3">
          <p className="error-message">Invalid ID</p>
        </div>
      </section>
    );
  if (networkIssue) return <Error500 message={failed} />;

  return (
    <section className="wrapper">
      <div className="d-flex flex-wrap gap-3 justify-content-between">
        <div className="d-flex align-items-center gap-2">
          <i
            className="bi bi-arrow-left-circle-fill fs-3 my-auto pointer"
            onClick={() => window.history.back()}
          ></i>
          <p className="heading" style={{ marginBottom: 0 }}>
            Product Updates Detail
          </p>
        </div>
      </div>
      {renderSection("Local", localItems)}
      {renderSection("Global & Regional", globalRegionalItems)}

      {showModal && (
        <>
          <ProductUpdatesDetailModal
            data={modalData}
            columns={modalColumns}
            onClose={() => setShowModal(false)}
          />
          <div className="modal-backdrop fade show"></div>
        </>
      )}
    </section>
  );
};

export default ProductUpdatesDetail;
