import React from "react";
import { Chart } from "react-google-charts";

const options = {
  colorAxis: { colors: ["#A9A9A9", "#FECDA6", "#FF9130", "#FF5B22"] },
  enableRegionInteractivity: true,
  magnifyingGlass: { enable: true, zoomFactor: 7.5 },
};

const GeoChart = ({ geoChartData, chartLoading }) => {
  const data = geoChartData;

  // console.log("Chart Loading:", chartLoading);
  // console.log("Geo Chart Data:", data);

  return (
    <>
      {chartLoading ? (
        <p className="fs-5 ms-4 w-100 h-100">Loading...</p>
      ) : data && data.length > 0 ? (
        <Chart
          chartType="GeoChart"
          width="100%"
          height="600px"
          data={data}
          options={options}
        />
      ) : (
        <p className="fs-5 ms-4 w-100 h-100">No data available</p>
      )}
    </>
  );
};

export default GeoChart;
