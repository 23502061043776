import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PartnersTable from "../../components/tables/PartnersTable";
import _ from "lodash";
import axios from "axios";
import { app_url } from "../../config";
import toast from "react-hot-toast";
import Error500 from "../../components/500/Error500";
import Pagination from "../../components/tables/Pagination";
import { DateRangePicker } from "react-date-range";
import { addDays, setSeconds, setHours, setMinutes } from "date-fns";
import { formatDate } from "../../formatDate/formatDate";
import { FaDollarSign, FaShoppingCart, FaUser } from "react-icons/fa";
import {
  MdOutlineSimCardAlert,
  MdOutlineSimCardDownload,
} from "react-icons/md";
import { VscVmActive } from "react-icons/vsc";
import { FcExpired } from "react-icons/fc";
import TopBundlesTable from "../../components/tables/TopBundlesTable";
import ChartOne from "../../components/charts/ChartOne";
import Leadsales from "../../components/charts/Leadsales";
import TopPerformingPartnersTable from "../../components/tables/TopPerformingPartnersTable";

const Partners = () => {
  const token = JSON.parse(localStorage.getItem("adminEtijwaal"))?.token;
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState("");
  const [dataOnPage, setDataOnPage] = useState(12);
  const [networkIssue, setNetworkIssue] = useState(false);
  const [failed, setFailed] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const datePickerRef = useRef(null);
  const [isActive, setIsActive] = useState("all");
  const [isLoading, setIsLoading] = useState(false);
  const [partnersData, setPartnerData] = useState([]);
  const [partnersDataLoading, setPartnerDataLoading] = useState([]);

  const handleClick = (buttonId) => {
    setIsActive(buttonId);
  };

  /* Date Range Picker */
  function getLastDayOfMonth(year, month) {
    const nextMonth = month + 1;
    const date = new Date(year, nextMonth, 0);
    return date.getDate();
  }
  const month = new Date().getMonth();
  const year = new Date().getFullYear();

  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  const getStartDateForInitialState = () => {
    const actualDate = addDays(new Date(), +1 - new Date().getDate());
    const adjustedStartDate = setSeconds(
      setMinutes(setHours(actualDate, 0), 0),
      0
    );
    return adjustedStartDate;
  };

  const [state, setState] = useState([
    {
      startDate: getStartDateForInitialState(),
      endDate: new Date(year, month, getLastDayOfMonth(year, month)),
      key: "selection",
    },
  ]);

  //update dateFrom and dateTo based on state changes
  useEffect(() => {
    const formattedStartDate = formatDate(state[0].startDate);
    const formattedEndDate = formatDate(state[0].endDate);

    setdateFrom(formattedStartDate);
    setdateTo(formattedEndDate);
  }, [state]);
  /* Date Range Picker end*/

  // Date Picker outside click handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setShowDatePicker(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  /* new Date Picker Data */
  const handleSelect = (ranges) => {
    const { startDate, endDate } = ranges.selection;
    const adjustedEndDate = setSeconds(
      setMinutes(setHours(endDate, 23), 59),
      59
    );
    const adjustedStartDate = setSeconds(
      setMinutes(setHours(startDate, 0), 0),
      0
    );
    setState([
      {
        startDate: adjustedStartDate,
        endDate: adjustedEndDate,
        key: "selection",
      },
    ]);
  };

  const [dateFrom, setdateFrom] = useState(formatDate(state[0].startDate));
  const [dateTo, setdateTo] = useState(formatDate(state[0].endDate));

  // Pagination Calculation
  const itemsPerPage = dataOnPage;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  useEffect(() => {
    setPartnerDataLoading(true);
    axios
      .get(
        `${app_url}/api/dashboard/getAdminPartnerDashboardDetails?from=${dateFrom}&to=${dateTo}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setPartnerData(res?.data?.data);
        setPartnerDataLoading(false);
      })
      .catch((err) => {
        setPartnerDataLoading(false);
        if (err.response) {
          const status = err.response.status;
          if (status === 400) {
            toast.error(err.response.data.message || "Bad Request");
          } else if (status === 401) {
            navigate("/");
          } else if (status === 500) {
            toast.error(err.response.data.message || "Internal Server Error");
          } else {
            toast.error("An unexpected error occurred.");
          }
        } else {
          setNetworkIssue(true);
          setFailed("Network error. Please check your internet connection.");
        }
      });
  }, [dateFrom, dateTo]);

  // Debounded Fetch function API Call
  const fetchPartners = useCallback(
    _.debounce((searchValue) => {
      setLoading(true);
      axios
        .get(
          `${app_url}/api/partner/getAllPartner?numPerPage=${dataOnPage}&page=${
            currentPage - 1
          }&search=${searchValue}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (itemsPerPage > res?.data?.pagination?.datCount) {
            setCurrentPage(1);
          }
          setTableData(res);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if (err.response) {
            const status = err.response.status;
            if (status === 400) {
              toast.error(err.response.data.message || "Bad Request");
            } else if (status === 401) {
              navigate("/");
            } else if (status === 500) {
              toast.error(err.response.data.message || "Internal Server Error");
            } else {
              toast.error("An unexpected error occurred.");
            }
          } else {
            setNetworkIssue(true);
            toast.error(
              "Network error. Please check your internet connection."
            );
            setFailed("Network error. Please check your internet connection.");
          }
        });
    }, 500),
    [dataOnPage, currentPage, token]
  );

  // useEffect to Fetch Data on Initial Render and Pagination Change
  useEffect(() => {
    fetchPartners(search);
    window.scrollTo(0, 0);
  }, [search, dataOnPage, currentPage, token]);

  // Search Function with Debounce
  const searchData = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  // Handle Pagination Page Change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (networkIssue) {
    return <Error500 message={failed} />;
  }

  return (
    <section className="wrapper">
      <div className="d-flex flex-wrap gap-3 justify-content-between">
        <div className="d-flex justify-content-between align-items-center w-100">
          <p className="heading mb-0"> Partners Center</p>
          <div className="d-flex gap-2">
            <Link to={"/partners/addPartner"} className="btn card-btn py-2">
              Add Partner
            </Link>
            <Link
              to={"/partners/addAccountManager"}
              className="btn card-btn py-2"
            >
              Add Account Manager
            </Link>
          </div>
        </div>
      </div>

      {/* Date Filter */}
      <div className="d-xl-flex px-3 py-3 justify-content-xl-between table-header-item mb-2">
        {/* new Date Picker Data */}
        <div className="dropdown d-flex flex-wrap gap-3" ref={datePickerRef}>
          <button
            className="btn btn-white border dropdown-toggle"
            type="button"
            onClick={toggleDatePicker}
          >
            Select Date
          </button>
          <div className="d-flex align-items-center gap-1 dropdown">
            <div className="order-date">
              <span className="pe-2">From:</span>
              <button
                type="button"
                disabled
                className="text-black btn btn-white border "
              >
                {dateFrom}
              </button>
            </div>
            <div className="order-date">
              <span className="pe-2">To:</span>
              <button
                type="button"
                disabled
                className="text-black btn btn-white border "
              >
                {dateTo}
              </button>
            </div>
          </div>
          {showDatePicker && (
            <ul
              // aria-labelledby="dropdownMenuClickableInside"
              className="dropdown-menu px-2 py-0 show"
              style={{ top: "46px", left: "-20px" }}
            >
              <li className="my-2 dropdown-item">
                <DateRangePicker
                  onChange={handleSelect}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={state}
                  direction="horizontal"
                />
              </li>
            </ul>
          )}
        </div>
      </div>

      <div className="row ">
        <div className="col-lg-3 col-sm-6 my-3">
          <div className="card w-100">
            <div className="card-body pointer d-flex justify-content-between align-items-center">
              <div>
                <p className="fs-5">Total Sales </p>
                <p className="heading m-0 text-black">
                  {partnersData?.eSim_data?.length > 0
                    ? partnersData?.eSim_data[0]?.total_sales.toFixed(2)
                    : "0.00"}
                </p>
              </div>
              <div>
                <div className="back-secondary rounded-3 px-2 py-1">
                  <FaDollarSign size={30} fill="#fff" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-sm-6 my-3">
          <div className="card w-100">
            <div className="card-body pointer d-flex justify-content-between align-items-center">
              <div>
                <p className="fs-5">Total eSIM Sold</p>
                <p className="heading m-0 text-black">
                  {partnersData?.eSim_data?.length > 0
                    ? partnersData?.eSim_data[0]?.esim_sold
                    : "0"}
                </p>
              </div>
              <div>
                <div className="back-secondary rounded-3 px-2 py-1">
                  <MdOutlineSimCardDownload size={30} fill="#fff" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-sm-6 my-3">
          <div className="card w-100">
            <div className="card-body pointer d-flex justify-content-between align-items-center">
              <div>
                <p className="fs-5">Total Topup Sold</p>
                <p className="heading m-0 text-black">
                  {partnersData?.eSim_data?.length > 0
                    ? partnersData?.eSim_data[0]?.esim_top_sold
                    : "0"}
                </p>
              </div>
              <div>
                <div className="back-secondary rounded-3 px-2 py-1">
                  <MdOutlineSimCardDownload size={30} fill="#fff" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-sm-6 my-3">
          <div className="card w-100">
            <div className="card-body pointer d-flex justify-content-between align-items-center">
              <div>
                <p className="fs-5">Customer Registered</p>
                <p className="heading m-0 text-black">
                  {partnersData?.customer_registration_count?.length > 0
                    ? partnersData?.customer_registration_count[0]?.customers
                    : "0"}
                </p>
              </div>
              <div>
                <div className="back-secondary rounded-3 px-2 py-1">
                  <FaUser size={30} fill="#fff" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row ">
        <div className="col-lg-4 col-sm-6 my-3">
          <div className="card w-100">
            <div className="card-body pointer d-flex justify-content-between align-items-center">
              <div>
                <p className="fs-5">active eSIMS </p>
                <p className="heading m-0 text-black">
                  {partnersData?.lineStatus?.length > 0
                    ? partnersData?.lineStatus[0]?.inactive_esims
                    : "0"}
                </p>
              </div>
              <div>
                <div className="back-secondary rounded-3 px-2 py-1">
                  <VscVmActive size={30} fill="#fff" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-sm-6 my-3">
          <div className="card w-100">
            <div className="card-body pointer d-flex justify-content-between align-items-center">
              <div>
                <p className="fs-5">Inactive eSIMS</p>
                <p className="heading m-0 text-black">
                  {partnersData?.lineStatus?.length > 0
                    ? partnersData?.lineStatus[0]?.active_esims
                    : "0"}
                </p>
              </div>
              <div>
                <div className="back-secondary rounded-3 px-2 py-1">
                  <i className="bi bi-sim-slash text-white fs-4"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-sm-6 my-3">
          <div className="card w-100">
            <div className="card-body pointer d-flex justify-content-between align-items-center">
              <div>
                <p className="fs-5">Expired Finished eSIMS</p>
                <p className="heading m-0 text-black">
                  {partnersData?.lineStatus?.length > 0
                    ? partnersData?.lineStatus[0]?.expired_finished_esims
                    : "0"}
                </p>
              </div>
              <div>
                <div className="back-secondary rounded-3 px-2 py-1">
                  <FcExpired size={30} fill="#fff" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* TOP BUNDLES */}
      <div className="row my-5">
        <div className="col-md-8">
          <div className="card border-0 h-100 ">
            <div className="card-body">
              <h1 className="pb-4 heading border-bottom mb-4">
                Top Bundles Sold (eSIM/Topup)
              </h1>

              <div className="">
                <TopBundlesTable
                  tabledata={partnersData}
                  isLoading={partnersDataLoading}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card border-0 h-100">
            <div className="card-body">
              <h2 className="pb-4 heading border-bottom mb-4">
                Country Metrics (eSIMS)
              </h2>
              {isLoading ? (
                <p className="fs-5">Loading...</p>
              ) : partnersData?.countryMetrics?.length === 0 ? (
                <p className="fs-5">No data found</p>
              ) : (
                <div
                  className="d-flex flex-column gap-3"
                  style={{
                    maxHeight: "41em",
                    overflow: "scroll",
                  }}
                >
                  {partnersData?.countryMetrics?.map((item, index) => {
                    const firstPackageCount =
                      partnersData?.countryMetrics[0]?.package_count || 0;
                    return (
                      <>
                        <div
                          className="align-items-center justify-content-between gap-2"
                          key={index}
                        >
                          <div
                            style={{ width: "100%", marginBottom: "5px" }}
                            className="fs-4 d-flex align-items-center"
                          >
                            {item?.flagImage_1 && (
                              <img
                                src={app_url + item?.flagImage_1}
                                alt=""
                                width={30}
                                className="me-2"
                              />
                            )}
                            <div className="d-flex justify-content-between w-100">
                              <span className="fs-5">
                                {item?.category.replace(/-/g, " ")}
                              </span>
                              <span className="fs-5 m-1">
                                {item?.package_count}
                              </span>
                            </div>
                          </div>
                          <div
                            className="progress"
                            style={{
                              width: "100%",
                            }}
                          >
                            <div
                              className="progress-bar progress-bar-striped progress-bar-animated"
                              role="progressbar"
                              aria-label="country metrices"
                              aria-valuenow={item?.package_count}
                              aria-valuemin="0"
                              aria-valuemax={firstPackageCount}
                              style={{
                                width: `${
                                  (item.package_count / firstPackageCount) * 100
                                }%`,
                              }}
                            ></div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* =========== Sparkline charts =========== */}
        <div className="mt-5">
          <div className="col-md-12 w-100">
            <div className="card border-0 h-100 ">
              <div className="card-body">
                <h1 className="pb-4 heading border-bottom mb-1">
                  Top Performing Partners
                </h1>

                <div className="card-body">
                  <TopPerformingPartnersTable />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div className="d-flex justify-content-center align-items-center mt-5">
        <input
          style={{ width: 350 }}
          className="form-control"
          type="search"
          placeholder="Search by Agent Name"
          aria-label="Search"
          value={search}
          onChange={searchData}
        />
      </div>

      {/* PartnersTable */}
      <PartnersTable tableData={tableData} isLoading={loading} />
      {/* Pagination */}
      <Pagination
        dataOnPage={dataOnPage}
        currentPage={currentPage}
        totalPages={Math.ceil(tableData?.data?.pagiantion.pages)}
        tabledata={tableData}
        onPageChange={handlePageChange}
        indexOfFirstItem={indexOfFirstItem}
        itemsPerPage={itemsPerPage}
        indexOfLastItem={indexOfLastItem}
      />
    </section>
  );
};

export default Partners;
