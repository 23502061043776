import React from "react";

const PartnerDiscountsModal = ({ discountPlan, handleModalClose }) => {
  // console.log(discountPlan, "partner data check");

  return (
    <div>
      <div className="modal-backdrop fade show"></div>
      <div className="modal show d-block" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Discount Plans</h5>
              <button
                type="button"
                className="btn-close"
                onClick={handleModalClose}
              ></button>
            </div>
            <div className="modal-body">
              <div
                className="table-responsive"
                style={{
                  maxHeight: "41em",
                  overflow: "scroll",
                  padding: "0",
                }}
              >
                <table className="table table-tb">
                  <thead className="">
                    <tr className="rounded">
                      <th className="tr-tt fw-bold ps-5" scope="col">
                        #
                      </th>
                      <th className="tr-tt fw-bold" scope="col">
                        Currency
                      </th>
                      <th className="tr-tt fw-bold" scope="col">
                        From
                      </th>
                      <th className="tr-tt fw-bold" scope="col">
                        To
                      </th>
                      <th className="tr-tt fw-bold" scope="col">
                        Discount %
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {discountPlan?.length == 0 ? (
                      <tr>
                        <th className="py-2"></th>
                        <th className="py-2"></th>
                        <th className="py-2 align-middle">No Data Found</th>
                        <th className="py-2"></th>
                        <th className="py-2"></th>
                      </tr>
                    ) : (
                      discountPlan?.map(
                        (
                          {
                            currency,
                            to_amount,
                            from_amount,
                            discount_percent,
                          },
                          index
                        ) => {
                          return (
                            <tr key={index}>
                              <th className="py-2 ps-5 align-middle">
                                {index + 1}
                              </th>
                              <th className="py-2 align-middle">{currency}</th>
                              <th className="py-2 align-middle">
                                {from_amount.toFixed(2)}
                              </th>
                              <th className="py-2 align-middle">
                                {to_amount.toFixed(2)}
                              </th>
                              <th className="py-2 align-middle">
                                {(discount_percent * 100).toFixed(2)}
                              </th>
                            </tr>
                          );
                        }
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerDiscountsModal;
