import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import debounce from "lodash.debounce";

const PartnerLeadsales = () => {
  const getLastFiveYears = () => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: 5 }, (_, i) => currentYear - i);
  };

  const [selectedYear, setSelectedYear] = useState(null);
  const [years, setYears] = useState(getLastFiveYears());
  const [width, setWidth] = useState(window.innerWidth);

  // Static Data
  const leadSales = Array(12).fill(0); // Static sales data (all zeros)
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    setSelectedYear(years[0]); // Default to the most recent year
  }, [years]);

  useEffect(() => {
    const handleResize = debounce(() => setWidth(window.innerWidth), 500);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const media = width >= 0 && width <= 758;

  const data = {
    labels: months,
    datasets: [
      {
        label: `Total Sales USD`,
        data: leadSales,
        backgroundColor: [
          "gray",
          "#ED8936",
          "gray",
          "#ED8936",
          "gray",
          "#ED8936",
          "gray",
        ],
        borderColor: "transparent",
        borderRadius: 5,
      },
    ],
  };

  return (
    <>
      <div className="my-4">
        {years.map((year) => (
          <button
            key={year}
            onClick={() => setSelectedYear(year)}
            className={
              selectedYear === year ? "btn ms-0 dark-btn" : "btn ms-0 chart-btn"
            }
            bg="transparent"
          >
            {year}
          </button>
        ))}
      </div>
      <Bar
        data={data}
        options={{
          plugins: {
            legend: { display: false },
          },
          responsive: true,
          scales: {
            x: { grid: { display: false } },
            y: {
              beginAtZero: true,
              grid: { drawTicks: false },
              ticks: {
                stepSize: 100,
                callback: (value) => `$${value}`,
              },
            },
          },
          aspectRatio: media ? 1.5 : 3,
        }}
      />
    </>
  );
};

export default PartnerLeadsales;
